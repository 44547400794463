import gql from 'graphql-tag';

import { orderFragment } from './fragments';

export const GET_ORDERS = gql`
  query getOrders($page: Int, $count: Int) {
    getOrders(page: $page, count: $count) {
      docs {
        ...AdminOrderData
      },
      limit,
      offset,
      total
    }
  }
  ${orderFragment}
`;

export const GET_ORDERS_STATS = gql`
  query getOrdersStats {
    getOrdersStats {
      revenue
      totalRevenue
      mrr
      mrr2
      activeUsers
      foreverCount
      foreverAmount
    }
  }
`;

export const GET_USER_ORDERS = gql`
  query getUserOrders($userId: ID!) {
    getUserOrders(userId: $userId) {
      ...AdminOrderData
    }
  }
  ${orderFragment}
`;

export const GET_USER_LOGS = gql`
  query getUserLogs($userId: ID!) {
    getUserLogs(userId: $userId) {
      _id
      userId
      userEmail
      userName
      text
      type
      createdAt
    }
  }
`;