import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Collapsible from 'react-collapsible';
import groupBy from 'lodash/groupBy';

import { Spinner } from '../../../core/ui/atoms';

const SOURCES_GROUP_MOCK = [
  { title: 'Органика', value: 323 },
  { title: 'Реклама', value: 11 },
  { title: 'Микроинфлюенсеры', value: 33 },
  { title: 'Инфлюенсеры', value: 33 },
];

const SOURCES_MOCK = [
  { title: 'Органика', value: 323 },
  { title: 'Инстаграм', value: 11 },
  { title: 'Реклама 1', value: 33 },
  { title: 'Реклама 2', value: 12 },
  { title: 'Блоггер 1', value: 42 },
  { title: 'Блоггер 2', value: 12 },
];

class UsersSources extends PureComponent {
  static propTypes = {
    result: PropTypes.object,
    sourcesRange: PropTypes.string,
  };

  render() {
    const { result: { loading, data } } = this.props;
    if (loading) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }
    const grouped = groupBy(data.getMetricsUsersSources, item => item.sourceGroup);
    return(
      <div>
        {Object.entries(grouped).map(([title, users]) => (
          <Collapsible key={`source-group-${title}`} trigger={`${title}: ${users.length}`}>
            <Ol>
              {Object.entries(groupBy(users, u => u.source)).map(([source, sourceUsers]) => (
                <li key={`source-list-${source}`}>{source}: <strong>{sourceUsers.length}</strong></li>
              ))}
            </Ol>
          </Collapsible>
        ))}
      </div>
    )
  };
}

const Ol = styled.ol`
  margin: 0;
  padding: 0 0 0 10px;
  > li {
    margin-bottom: 6px;
  }
  > strong {
    margin-left: 6px;
  }
`;

export default UsersSources;
