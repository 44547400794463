import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Editor from 'react-medium-editor';

import 'medium-editor/dist/css/medium-editor.css';
import 'medium-editor/dist/css/themes/default.css';

class MediumEditor extends PureComponent {
  static propTypes = {
    html: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    html: '',
  };

  state = {
    html: this.props.html,
  };

  handleChange = (html) => {
    this.setState({ html });
    this.props.onChange(html);
  };

  render() {
    return <Editor text={this.state.html} onChange={this.handleChange} />;
  }
}

export default MediumEditor;
