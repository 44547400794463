import gql from 'graphql-tag';

export const ADD_PRACTICE_AUDIO = gql`
  mutation addPracticeAudio($data: AddPracticeAudioInput!) {
    addPracticeAudio(data: $data) {
      _id
      key
      type
      content
    }
  }
`;

export const REMOVE_PRACTICE_AUDIO = gql`
  mutation removePracticeAudio($data: RemovePracticeAudioInput!) {
    removePracticeAudio(data: $data) {
      _id
      key
      type
      content
    }
  }
`;

export const ADD_PRACTICE_AUDIO_PAD = gql`
  mutation addPracticeAudioPad($data: AddAudioPadInput!) {
    addPracticeAudioPad(data: $data) {
      _id
      key
      type
      content
    }
  }
`;

export const ADD_MUSIC = gql`
  mutation addMusic($data: AddMusicInput!) {
    addMusic(data: $data) {
      _id
      audio {
        url
        path
        name
        checksum
      }
      content
    }
  }
`;

export const REMOVE_MUSIC = gql`
  mutation removeMusic($data: RemoveMusicInput!) {
    removeMusic(data: $data) {
      _id
    }
  }
`;

export const UPDATE_MUSIC_TITLE = gql`
  mutation updateMusicTitle($data: UpdateMusicTitleInput!) {
    updateMusicTitle(data: $data) {
      _id
      audio {
        url
        path
        name
        checksum
      }
      content
    }
  }
`;

export const ADD_SHORT_PRACTICE = gql`
  mutation addShortPractice($key: String!) {
    addShortPractice(key: $key) {
      _id
      key
      type
      content
    }
  }
`;

export const ADD_DREAM_PRACTICE = gql`
  mutation addDreamPractice($key: String!, $type: DreamPracticeType!) {
    addDreamPractice(key: $key, type: $type) {
      _id
      key
      type
      content
    }
  }
`;

export const ADD_DREAM_PRACTICE_AUDIO = gql`
  mutation addDreamPracticeAudio($data: AddDreamPracticeAudioInput!) {
    addDreamPracticeAudio(data: $data) {
      _id
      key
      type
      content
    }
  }
`;

export const REMOVE_DREAM_PRACTICE_AUDIO = gql`
  mutation removeDreamPracticeAudio($data: RemoveDreamPracticeAudioInput!) {
    removeDreamPracticeAudio(data: $data) {
      _id
      key
      type
      content
    }
  }
`;
