import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';

import DayAudio from 'days/ui/components/DayAudio';
import { Button } from 'core/ui/atoms';
import { REMOVE_PRACTICE_AUDIO, ADD_PRACTICE_AUDIO_PAD } from '../../api/apollo/mutations';
import SilenceInput from './SilenceInput';

const AudioItem = ({ practiceId, part, path, locale, url, pad, _id }) => {
  const data = {
    _id,
    locale,
    part,
    practiceId,
    path,
  };
  return (
    <Mutation mutation={ADD_PRACTICE_AUDIO_PAD}>
      {addPad => (
        <Mutation mutation={REMOVE_PRACTICE_AUDIO} variables={{ data }}>
          {remove => (
            <>
              <Cont>
                <Title>{path}</Title>
                <DayAudio src={url} noUpdate />
                <Close onClick={remove}>&times;</Close>
              </Cont>
              <SilenceInput
                mutate={addPad}
                data={{
                  _id,
                  locale,
                  part,
                  practiceId,
                }}
                pad={pad}
              />
            </>
          )}
        </Mutation>
      )}
    </Mutation>
  )
};

AudioItem.propTypes = {
  url: PropTypes.string,
  _id: PropTypes.string,
  path: PropTypes.string,
  practiceId: PropTypes.string,
  part: PropTypes.string,
  locale: PropTypes.string,
  pad: PropTypes.number,
};

const Cont = styled.div`
  position: relative;
  padding: 12px 50px 23px 12px;
  border-radius: 6px;
  box-shadow: ${p => p.theme.shadows.level2};
  margin-bottom: 13px;
`;

const Title = styled.h5`
  font-size: 16px;
  margin: 0;
`;

const Close = styled(p => <Button {...p} unstyled />)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 33px;
  color: ${p => p.theme.colors.primary};
`

export default AudioItem;
