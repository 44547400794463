import styled, { css } from 'styled-components';

import { Text } from 'core/ui/atoms';

const TextContent = styled(Text)`
  &&, p {
    color: ${p => p.theme.colors.gray.content};
    margin: 0;
    font-size: 12px;
    ${p => p.bold && css`
      font-weight: bold;
    `}
    ${p => p.margin && css`
      margin-top: ${p.margin}px;
    `}
  }
`;

export default TextContent;
