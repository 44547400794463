import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import compose from 'lodash/flowRight';
import debounce from 'lodash/debounce';

import {
  Card,
  Button,
  Label,
  Flex,
  Input,
  Textarea,
} from 'core/ui/atoms';
import {
  REMOVE_STAGE,
  UPDATE_STAGE_DAYS,
  UPDATE_STAGE_CONTENT_TEXT,
} from '../../api/apollo/mutations';
import { LIST_STAGES } from '../../api/apollo/queries';

@compose(
  graphql(REMOVE_STAGE, { name: 'removeStage' }),
  graphql(UPDATE_STAGE_DAYS, { name: 'updateStageDays' }),
  graphql(UPDATE_STAGE_CONTENT_TEXT, { name: 'updateStageContentText' }),
)
class TimelineItem extends PureComponent {
  static propTypes = {
    stage: PropTypes.object,
    index: PropTypes.number,
    locale: PropTypes.string,
    removeStage: PropTypes.func,
    updateStageDays: PropTypes.func,
    updateStageContentText: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setContentState(this.props);
  }
  componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.setContentState(this.props);
    }
  }

  setContentState = (props) => {
    let title = '';
    let desc = '';
    const content = props.stage.content ? props.stage.content[props.locale] : null;
    if (content) {
      title = content.title || '';
      desc = content.desc || '';
    }
    this.setState({
      days: props.stage.days,
      title,
      desc,
    });
  };

  _updateStageContentText = debounce((name, value) => {
    const { stage: { _id }, locale, updateStageContentText } = this.props;
    updateStageContentText({
      variables: {
        _id,
        locale,
        name,
        value,
      }
    });
  }, 300);

  onChangeInput = e => {
    const { target: { name, value } } = e;
    this.setState({ [name]: value });
    this._updateStageContentText(name, value);
  };

  remove = () => {
    const { stage: { _id }, removeStage } = this.props;
    removeStage({
      variables: {
        _id,
      },
      refetchQueries: [
        { query: LIST_STAGES }
      ]
    });
  };

  updateStageDays = (e) => {
    const { target: { value } } = e;
    const { updateStageDays, stage: { _id } } = this.props;
    const days = parseInt(value);
    this.setState({ days }, () => {
      updateStageDays({
        variables: {
          _id,
          days,
        }
      });
    });
  };

  render() {
    const { days, title, desc } = this.state;
    return (
      <Card>
        <Content>
          <Close onClick={this.remove}>&times;</Close>
          <FormItem>
            <FormLabel>Название:</FormLabel>
            <InputCont>
              <Input
                value={title}
                name={'title'}
                onChange={this.onChangeInput}
              />
            </InputCont>
          </FormItem>
          <FormItem>
            <FormLabel>Кол-во дней:</FormLabel>
            <InputCont>
              <Input
                value={days}
                onChange={this.updateStageDays}
                type={'number'}
              />
            </InputCont>
          </FormItem>
          <FormItem>
            <FormLabel>Описание:</FormLabel>
            <InputCont>
              <Textarea
                rows={9}
                value={desc}
                name={'desc'}
                onChange={this.onChangeInput}
              />
            </InputCont>
          </FormItem>
        </Content>
      </Card>
    );
  }
}

const Close = styled(Button).attrs({
  unstyled: true,
})`
  position: absolute;
  top: -3px;
  right: 7px;
  font-size: 33px;
`;

const Content = styled.div`
  position:relative;
  width: 600px;
  padding: 23px;
`;

const FormItem = styled(Flex)`
  margin: 13px 0;
`;

const FormLabel = styled(Label)`
  width: 100px;
`;

const InputCont = styled.div`
  flex-grow: 1;
`;

export default TimelineItem;
