// Layouts
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import UsersPage from 'users/ui/pages/UsersPage';
import UserPage from 'users/ui/pages/UserPage';
import PendingUsersPage from 'users/ui/pages/PendingUsersPage';
import CurrentDaysPage from 'users/ui/pages/CurrentDays';

export default [
  {
    component: UsersPage,
    layout: AuthenticatedLayout,
    path: '/users/',
    exact: true
  },
  {
    component: PendingUsersPage,
    layout: AuthenticatedLayout,
    path: '/users/pending/'
  },
  {
    component: CurrentDaysPage,
    layout: AuthenticatedLayout,
    path: '/users/current-days/'
  },
  {
    component: UserPage,
    layout: AuthenticatedLayout,
    path: '/user/:id'
  },
];
