import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AudioPlayer from 'react-h5-audio-player';

import withUpdateDay from 'days/ui/withUpdateDay';

@withUpdateDay
class DayAudio extends PureComponent {

  player = null;

  static propTypes = {
    updateDay: PropTypes.func,
    src: PropTypes.string,
    full: PropTypes.bool,
    time: PropTypes.number,
    noUpdate: PropTypes.bool,
    part: PropTypes.string,
  };

  componentDidMount() {
    if (this.player && !this.props.noUpdate) {
      this.player.audio.onloadedmetadata = () => {
        const { time, updateDay } = this.props;
        if (!time) {
          updateDay({ time: this.player.audio.duration });
        }
      }
    }
  }

  render() {
    return (
      <Audio
        full={this.props.full}
        autoPlay={false}
        preload="none"
        src={this.props.src}
        ref={c => (this.player = c)}
      />
    );
  }
}

export default DayAudio;

const Audio = styled(AudioPlayer)`
  width: ${p => p.full && '100%' || '184px'};
  height: 14px;
  padding-top: 10px;
  &.react-h5-audio-player {
    .flex {
      padding: 0 !important;
      box-shadow: none !important;
      height: auto !important;
      .toggle-play-wrapper {
        flex: 1 0 20px !important;
        .toggle-play-button {
          background: none !important;
          width: 20px !important;
          height: 20px !important;
          padding: 0 !important;
          left: 0 !important;
          position: inherit !important;
          margin-left: 0 !important;
          .play-icon {
            left: 0 !important;
            border-top: 8px solid transparent !important;
            border-bottom: 8px solid transparent !important;
            border-left: 16px solid ${p => p.theme.colors.primary} !important;
          }
          .pause-icon {
            left: 0 !important;
            height: 16px !important;
            width: 12px !important;
            background: ${p => p.theme.colors.primary} !important; 
            border-left: 6px solid ${p => p.theme.colors.primary} !important;
            box-shadow: white 6px 0 0 0 inset !important;
          }
        }
      }
      .progress-bar-wrapper {
        padding: 0 !important;
        flex: 20 0 auto !important;
        .progress-bar {
          border-radius: 2px;
          height: 4px !important;
          background: #EFEFEF !important;
        }
        .indicator {
          background: ${p => p.theme.colors.primary} !important;
          box-shadow: none !important;
          top: -5px !important;
          width: 14px !important;
          height: 14px !important;
          opacity: 1;
        }
        .audio-info {
          display: none !important;
        }
      }
    }
    
  }
  .toggle-play-button {
  
  }
`;

