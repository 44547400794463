import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import { Card, Heading, Flex, Spinner } from '../../../core/ui/atoms';
import CheckoutItem from './CheckoutItem';

class Checkouts extends PureComponent {
  static propTypes = {
    checkouts: PropTypes.arrayOf(PropTypes.object),
  };

  render() {
    const { checkouts } = this.props;
    return (
      <Card padded>
        <Heading>Бронирования:</Heading>
        <div>
          {checkouts.map(c => <CheckoutItem key={c._id} checkout={c} />)}
        </div>
      </Card>
    );
  }
}

export default Checkouts;
