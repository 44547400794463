import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { Flex, Text, SvgIcon, Button } from 'core/ui/atoms';

const BACKGROUND_COLOR_MAP = {
  SUCCESS: 'success',
  ERROR: 'danger'
};

const ToastContent = ({ notification }) => {
  const {
    message,
    title,
    type,
    confirm,
    confirmText,
    skip,
    skipText,
  } = notification;

  const [showNotification, setShowNotification] = useState(true);

  const hideNotification = () => {
    setShowNotification(false);
  };

  const confirmAction = () => {
    hideNotification();
    confirm();
  };

  const skipAction = () => {
    hideNotification();
    skip();
  };

  return (
    <Context type={type} show={showNotification}>
      <TextCont type={type} direction={'column'}>
        <Heading>
          {title}
        </Heading>
        <Message>
          {message}
        </Message>
      </TextCont>
      <ButtonCont>
        {confirm && <ConfirmButton success onClick={confirmAction}>{confirmText || 'Да'}</ConfirmButton>}
        {skip && <ConfirmButton onClick={skipAction}>{skipText || 'Нет'}</ConfirmButton>}
      </ButtonCont>
      {(!confirm && !skip) ? <CloseNotification onClick={hideNotification} /> : null}
    </Context>
  );
};

ToastContent.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    confirm: PropTypes.func,
    confirmText: PropTypes.string,
    skip: PropTypes.func,
    skipText: PropTypes.string,
  }),
};

const animationToastRight = keyframes`
 0% { transform: translateX(100%); }
 30% { transform: translateX(-10%); }
 70% { transform: translateX(5%); }
 100% { transform: translateX(0); }
`;

const Context = styled.div`
  background: ${p => p.theme.colors[BACKGROUND_COLOR_MAP[p.type]] || p.theme.colors.gray.regular };
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: transform .6s ease-in-out;
	animation: ${animationToastRight} 1s;
	border-radius: 8px;
	margin-bottom: 8px;
	position: relative;
	display: ${p => p.show ? 'flex' : 'none'};
`;

const Heading = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: white;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
`;

const Message = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: white;
  margin: 0;
  padding: 0;
`;

const TextCont = styled(Flex)`
  width: 100%;
  position: relative;
  padding: 16px 40px 16px 13px;
`;

const CloseNotification = styled(SvgIcon.Close)`
  color: white;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 13px;
  right: 0;
  cursor: pointer;
`;

const ButtonCont = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > button:first-child {
    border-top-right-radius: 8px;
  }
  > button:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const ConfirmButton = styled.button`
  flex-grow: 1;
  background: transparent;
  border: 0;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  padding: 0 13px;
  color: ${p => p.theme.colors[p.success ? 'green' : 'redCancel']};
  cursor: pointer;
  &:hover {
    background: ${p => p.theme.colors.gray.dark};
  }
`;

export default ToastContent;

