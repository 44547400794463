import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Heading, Card, Flex } from 'core/ui/atoms';
import history from 'core/api/history';

const getPropLength = (audio, name) =>
  audio[name] ? audio[name].length : 0;

class PracticeItem extends PureComponent {
  static propTypes = {
    _id: PropTypes.string,
    practice: PropTypes.object,
    locale: PropTypes.oneOf(['en', 'ru']),
  };

  get name() {
    const { practice: { key } } = this.props;
    return key || 'Названия еще нет';
  }

  getLength = (name) => {
    const { practice: { content }, locale } = this.props;
    const { audio } = content[locale];
    if (!audio) {
      return 0;
    }
    if (name === 'total') {
      return getPropLength(audio, 'theory') +
        getPropLength(audio, 'practice') +
        getPropLength(audio, 'support') +
        getPropLength(audio, 'integration');
    } else {
      return getPropLength(audio, name);
    }
  };

  goToPractice = () => {
    const { practice: { _id }, locale } = this.props;
    history.push(`/tracker/${locale}/${_id}`)
  };

  render() {
    return (
      <Wrap md={4} sm={12} xs={12}>
        <Card small>
          <Flex alignContent={'center'}>
            <DayNumber onClick={this.goToPractice}>
              <DayHeading>{this.getLength('total')}</DayHeading>
              <BlueBackground/>
            </DayNumber>
            <DayContent direction={'column'} justifyContent={'center'}>
              {this.name}
              <div>
                <span>sta: <b>{this.getLength('theory')}</b></span>{' '}
                <span>pra: <b>{this.getLength('practice')}</b></span>{' '}
                <span>sup: <b>{this.getLength('support')}</b></span>{' '}
                <span>int: <b>{this.getLength('integration')}</b></span>
              </div>
            </DayContent>
          </Flex>
        </Card>
      </Wrap>
    );
  }

}

export default PracticeItem;

const Wrap = styled(Box)`
  padding: 5px;
`;

const DayNumber = styled.div`
  position: relative;
  background: ${p => `url(${p.image})` || ''};
  background-size: cover;
  min-width: 66px;
  max-width: 66px;
  min-height: 66px;
  max-height: 66px;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  span {
    position: relative;
    top: -10px;
    font-size: 17px;
    color: #fff;
    margin: 0;
    z-index: 3;
  }
`;

const DayHeading = styled(p => <Heading level={1} {...p} />)`
  position: relative;
  font-size: 48px;
  margin: 0;
  z-index: 3;
  color: #fff !important;
`;

const BlueBackground = styled.div`
  position: absolute;
  top: 0;
  min-width: 66px;
  max-width: 66px;
  min-height: 66px;
  max-height: 66px;
  background-color: ${p => p.theme.colors.primary};
  opacity: .7;
  z-index: 2;
`;

const DayContent = styled(Flex)`
  height: 66px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #B5B5B5;
`;
