export const timeFormat = (sec) => {
  if (!sec) {
    return '';
  }
  const h   = Math.floor(sec / 3600);
  const m = Math.floor((sec - (h * 3600)) / 60);
  const s = sec - (h * 3600) - (m * 60);
  const hours= `0${h}`.slice(-2);
  const minutes= `0${m}`.slice(-2);
  const seconds= `0${s}`.slice(-2);
  return `${hours}:${minutes}:${seconds}`
};
