// Layouts
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import DaysPage from 'days/ui/pages/DaysPage';
import DayContentPage from 'days/ui/pages/DayContentPage';

export default [
  {
    component: DaysPage,
    layout: AuthenticatedLayout,
    path: '/days'
  },
  {
    component: DayContentPage,
    layout: AuthenticatedLayout,
    path: '/day-content/:locale/:day'
  },
];
