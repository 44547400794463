import gql from 'graphql-tag';

import { singleUserData } from 'users/api/apollo/fragments';

export const ADMIN_SEND_TO_DAY = gql`
  mutation adminSendToDay($email: String, $_id: String, $day: String!) {
    adminSendToDay(email: $email, _id: $_id, day: $day) {
      success
      error
    }
  }
`;

export const ADMIN_SEND_TO_NIGHT = gql`
  mutation adminSendToNight($email: String, $_id: String, $night: String!) {
    adminSendToNight(email: $email, _id: $_id, night: $night) {
      success
      error
    }
  }
`;

export const ADMIN_MAX_STREAK = gql`
  mutation adminMaxStreak($email: String, $_id: String, $streak: Int!) {
    adminMaxStreak(email: $email, _id: $_id, streak: $streak) {
      ...SingleUserData
    }
  }
  ${singleUserData}
`;

export const CANCEL_RECURRENT_SUBSCRIPTIONS = gql`
  mutation adminCancelRecurrentSubscriptions($email: String, $_id: String) {
    adminCancelRecurrentSubscriptions(email: $email, _id: $_id) {
      ...SingleUserData
    }
  }
  ${singleUserData}
`;

export const ADMIN_FREE_COURSE = gql`
  mutation AdminFreeCourse($emails: [String]!, $days: Int!) {
    adminFreeCourse(emails: $emails, days: $days) {
      success
      failed
    }
  }
`;

export const UPDATE_SUBSCRIPTION_STATUS = gql`
  mutation adminUpdateSubscriptionStatus {
    adminUpdateSubscriptionStatus
  }
`;

export const APPLY_SHOULD_CANCEL_SUBSCRIPTION = gql`
  mutation adminApplyShouldCancelSubscriptions {
    adminApplyShouldCancelSubscriptions
  }
`;

export const CLEAR_SHOULD_CANCEL_SUBSCRIPTION = gql`
  mutation adminClearShouldCancelSubscriptions {
    adminClearShouldCancelSubscriptions
  }
`;

export const CLONE_USER_DAYS = gql`
  mutation systemCloneUserDaysToSessions {
    systemCloneUserDaysToSessions
  }
`;