// Layouts
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import OperationsPage from 'operations/ui/pages/Operations';

export default [
  {
    component: OperationsPage,
    layout: AuthenticatedLayout,
    path: '/operations'
  },
];
