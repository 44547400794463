import gql from 'graphql-tag';

import { dayFragment } from './fragments';

export const LIST_DAY = gql`
  query adminDayList {
    adminDayList {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;

export const SINGLE_DAY = gql`
  query adminDaySingle($day: Int!) {
    adminDaySingle(day: $day) {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;
