import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spinner } from '../../../core/ui/atoms';

const DaysGraph = ({ result }) => {
  if (result.loading) {
    return (
      <Loading>
        <Spinner />
      </Loading>
    );
  }

  const { data: { getMetricsBehaviorDays } } = result;
  const max = getMetricsBehaviorDays[0].value;
  return (
    <Cont>
      {getMetricsBehaviorDays.map(({ title, value }, i) => (
        <StripeCont key={`day-graph-${i}`} percentage={value / max * 93}>
          <p>{value}</p>
          <div />
          <p>{title}</p>
        </StripeCont>
      ))}
    </Cont>
  )
};

DaysGraph.propTypes = {
  result: PropTypes.object,
};

const Cont = styled.div`
  position:relative;
  width: 999px;
  height: 299px;
  display: flex;
  align-items: flex-end;
`;

const Loading = styled.div`
  width: 799px;
  height: 299px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f6f6;
  border-radius: 6px;
`;

const StripeCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 16px;
  height: 100%;
  margin-right: 6px;
  > p {
    margin: 0;
    font-size: 9px;
    text-align: center;
    font-weight: 600;
    &:last-child {
      font-weight: 800;
    }
  }
  > div {
    position: relative;
    width: 100%;
    height: ${p => p.percentage}%;
    background: ${p => p.theme.colors.secondary};
  }
`;

export default DaysGraph;
