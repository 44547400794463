import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { html2json } from 'html2json';
import debounce from 'lodash/debounce';

import { Box } from 'core/ui/atoms';
import { EditHeading, EditBlock } from 'days/ui/atoms';
import MediumEditor from 'core/ui/components/MediumEditor';

import withUpdateDay from 'days/ui/withUpdateDay';

@withUpdateDay
class DayText extends PureComponent {
  static propTypes = {
    description: PropTypes.string,
    updateDay: PropTypes.func,
  };

  state = {
    description: this.props.description || '',
  };

  save = debounce(() => {
    const { description } = this.state;
    this.props.updateDay({
      description,
      json: html2json(description),
    });
  }, 1000);

  handleChange = (description) => {
    this.setState({ description });
    this.save();
  };

  render() {
    const { description } = this.state;
    return (
      <EditBlock>
        <Box width="100%">
          <EditHeading>Описание:</EditHeading>
          <MediumEditor
            html={description}
            onChange={this.handleChange}/>
        </Box>
      </EditBlock>
    );
  }
}

export default DayText;
