import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import OrdersStatsCard from 'orders/ui/components/OrdersStatsCard';

const OrdersStats = ({ stats }) => {
  const {
    mrr,
    mrr2,
    revenue,
    totalRevenue,
    activeUsers,
    foreverCount,
    foreverAmount,
  } = stats;
  return (
    <Container>
      <h2>Статистика</h2>
      <Cont>
        <OrdersStatsCard
          title={'Total revenue'}
          value={`${totalRevenue} ₽`}
          additional={'In total'}
        />
        <OrdersStatsCard
          title={'Revenue'}
          value={`${revenue} ₽`}
          additional={'Last 28 days'}
        />
        <OrdersStatsCard
          title={'MRR'}
          value={`${mrr} ₽`}
          additional={'Monthly recurring revenue'}
        />
        <OrdersStatsCard
          title={'MRR2'}
          value={`${mrr2} ₽`}
          additional={'Monthly recurring revenue 2'}
        />
        <OrdersStatsCard
          title={'Active users'}
          value={`${activeUsers}`}
          additional={'In total'}
        />
        <OrdersStatsCard
          title={'Вечные подписки'}
          value={`${foreverAmount} ₽`}
          additional={`${foreverCount} заказов`}
        />
      </Cont>
    </Container>
  );
};

OrdersStats.propTypes = {
  stats: PropTypes.object,
};

const Container = styled.div`
  padding: 20px 30px;
  background-color: #fafafa;
`;

const Cont = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
`;

export default OrdersStats;
