import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { ADD_DAY } from 'days/api/apollo/mutations';
import { LIST_DAY } from 'days/api/apollo/queries';

const withAddDay = Component => (
  @withRouter
  @graphql(ADD_DAY, { name: 'addDay' })
  class Wrapper extends PureComponent {
    static propTypes = {
      name: PropTypes.string,
      addDay: PropTypes.func,
      match: PropTypes.shape({
        params: PropTypes.shape({
          day: PropTypes.number,
          locale: PropTypes.string,
        }),
      }),
    };

    addDay = () => {
      const { addDay, match: { params: { day: d, locale } } } = this.props;
      return addDay({
        refetchQueries: [
          { query: LIST_DAY }
        ]
      });
    };

    render() {
      const { addDay, ...rest } = this.props;
      return (
        <Component {...rest} addDay={this.addDay} />
      );
    }
  }
);

export default withAddDay;
