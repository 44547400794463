import gql from 'graphql-tag';

export const GET_USERS_SOURCES = gql`
  query getUsersSources {
    getUsersSources {
      sourceGroups
      sources
    }
  }
`;

export const GET_METRICS_USERS_STATIC = gql`
  query getMetricsUsersStatic {
    getMetricsUsersStatic {
      total
      totalActive
      today
      paymentsToday
      paymentsShownToday
      paymentsSkippedToday 
      reviewsToday
      reviewsSkippedToday
    }
  }
`;

export const GET_METRICS_USERS_SOURCES = gql`
  query getMetricsUsersSources($range: PreciseRange!) {
    getMetricsUsersSources(range: $range) {
      sourceGroup
      source
    }
  }
`;

export const GET_METRICS_USERS_GRAPH = gql`
  query getMetricsUsersGraph($range: GraphRange!) {
    getMetricsUsersGraph(range: $range) {
      sourceGroup
      createdAt
      device
    }
  }
`;

export const GET_METRICS_MONETIZE_STATIC = gql`
  query getMetricsMonetizeStatic {
    getMetricsMonetizeStatic {
      totalPayments
      totalSkipped
      todayPayments
      todaySkipped
      todayTrials
    }
  }
`;

export const GET_METRICS_MONETIZE_GRAPH = gql`
  query getMetricsMonetizeGraph($range: GraphRange!, $graphType: String!) {
    getMetricsMonetizeGraph(range: $range, graphType: $graphType) {
      sourceGroup
      createdAt
      device
    }
  }
`;

export const GET_METRICS_MONETIZE_TOTAL = gql`
  query getMetricsMonetizeTotal($input: GetMetricsMonetizeTotalInput!) {
    getMetricsMonetizeTotal(input: $input) {
      data
    }
  }
`;

export const GET_METRICS_BEHAVIOR_DAYS = gql`
  query getMetricsBehaviorDays($type: String!) {
    getMetricsBehaviorDays(type: $type) {
      title
      value
    }
 }
`;

export const GET_METRICS_BEHAVIOR_GRAPH = gql`
  query getMetricsBehaviorGraph($range: GraphRange!) {
    getMetricsBehaviorGraph(range: $range) {
      instagram {
        date
      }
      telegram {
        date
      }
      review {
        date
      }
    }
 }
`;

export const GET_METRICS_BEHAVIOR_STATIC = gql`
  query getMetricsBehaviorStatic($range: PreciseRange!, $device: String!) {
    getMetricsBehaviorStatic(range: $range, device: $device) {
      reviewTotal
      reviewConversion
      instagramTotal
      instagramConversion
      telegramTotal
      telegramConversion
    }
 }
`;
