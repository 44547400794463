import gql from 'graphql-tag';

import { nightFragment } from './fragments';

export const ADD_NIGHT = gql`
  mutation adminAddNight {
    adminAddNight {
      ...AdminNightData
    }
  }
  ${nightFragment}
`;

export const UPDATE_NIGHT = gql`
  mutation adminUpdateNight($data: AdminNightInput!) {
    adminUpdateNight(data: $data) {
      ...AdminNightData
    }
  }
  ${nightFragment}
`;

export const UPLOAD_FILE_NIGHT = gql`
  mutation adminUpdateNightUpload($data: AdminNightUploadInput) {
    adminUpdateNightUpload(data: $data) {
      ...AdminNightData
    }
  }
  ${nightFragment}
`;

export const ADD_PRACTICE_NIGHT_AUDIO = gql`
  mutation adminAddPracticeNightAudio($data: AdminAddPracticeNightAudioInput!) {
    adminAddPracticeNightAudio(data: $data) {
      ...AdminNightData
    }
  }
  ${nightFragment}
`;

export const ADMIN_ADD_NIGHT_AUDIO_PAD = gql`
  mutation adminAddNightAudioPad($data: AdminAddNightAudioPadInput!) {
    adminAddNightAudioPad(data: $data) {
      ...AdminNightData
    }
  }
  ${nightFragment}
`;
