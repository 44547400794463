import gql from 'graphql-tag';

export const LIST_RETREAT_CHECKOUTS = gql`
  query listRetreatCheckouts {
    listRetreatCheckouts {
      _id
      title
      type
      status
      checkoutData
      createdAt
    }
  }
`;
