import gql from 'graphql-tag';

export const stageFragment = gql`
  fragment StageFull on Stage {
    _id
    days
    createdAt
    content
  }
`;
