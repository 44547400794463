import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Cover, Spinner } from 'core/ui/atoms';

class Loading extends PureComponent {
  render() {
    return (
      <LoadingCont>
        <Spinner/>
      </LoadingCont>
    );
  }
}

export default Loading;

const LoadingCont = styled(Cover)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

