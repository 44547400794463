import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { Page, PageTitle } from 'core/ui/atoms';
import OrdersTable from 'orders/ui/components/OrdersTable';

import { SINGLE_USER } from 'users/api/apollo/queries';
import { GET_USER_ORDERS, GET_USER_LOGS } from 'orders/api/apollo/queries';
import { getIsSubscribed } from 'core/ui/helpers';

const defaultUserData = {
  _id: '1',
  personalData: {
    firstName: 'User',
    email: 'user@sati.app',
  },
  serviceData: {},
  createdAt: Date.now(),
};

const UserPage = ({
  match: {
    params: { id },
  },
}) => {
  const { data, loading, error } = useQuery(SINGLE_USER, {
    variables: {
      _id: id,
    },
  });
  const userData = (data && data.adminUserSingle) || defaultUserData;

  const {
    data: ordersData,
    loading: ordersLoading,
    error: ordersError,
  } = useQuery(GET_USER_ORDERS, {
    variables: {
      userId: id,
    },
  });
  const userOrders = (ordersData && ordersData.getUserOrders) || [];

  const {
    data: logsData,
    loading: logsLoading,
    error: logsError,
  } = useQuery(GET_USER_LOGS, {
    variables: {
      userId: id,
    },
  });
  const userLogs = (logsData && logsData.getUserLogs) || [];
  const onesignalToken = userData.serviceData.notifications && userData.serviceData.notifications.token;

  return (
    <Page>
      <PageTitle />
      <Wrap>
        <h2>{userData.personalData.firstName}</h2>
        <UserInfo>
          <p>
            <span>Email: </span>
            {userData.personalData.email}
          </p>
          <p>
            <span>_id: </span>
            {userData._id}
          </p>
          <p>
            <span>Подписка: </span>
            {getIsSubscribed(
              userData.serviceData.expirationDate,
              userData.serviceData.expirationDateWeb,
              userData.serviceData.forceSubscribed
            )
              ? 'Есть'
              : 'Нет'}
          </p>
          <p>
            <span>Покупка в приложении: </span>
            {userData.serviceData.expirationDate
              ? moment(userData.serviceData.expirationDate).format('DD.MM.YYYY')
              : 'Нет'}
          </p>
          <p>
            <span>Покупка через веб: </span>
            {userData.serviceData.expirationDateWeb
              ? moment(userData.serviceData.expirationDateWeb).format(
                'DD.MM.YYYY'
              )
              : 'Нет'}
          </p>
          <p>
            <span>Доступ в админке: </span>
            {userData.serviceData.forceSubscribed
              ? moment(userData.serviceData.forceSubscribed).format(
                'DD.MM.YYYY'
              )
              : 'Нет'}
          </p>
          <p>
            <span>Рекуррентные списания (web): </span>
            {userData.serviceData.recurrentPayment ? 'Включены' : 'Выключены'}
          </p>
          {onesignalToken ? (
            <p>
              <span>Токен Onesignal: </span>
              {onesignalToken}
            </p>
          ) : null}
          <p>
            <span>Текущий рекорд: </span>
            {userData.serviceData.currentStreak}
          </p>
          <p>
            <span>Максимальный рекорд: </span>
            {userData.serviceData.maxStreak}
          </p>
        </UserInfo>

        <div>
          <h3>История</h3>
          {userLogs.map((l) => (
            <p key={l._id}>
              {moment(l.createdAt).format('DD.MM.YYYY HH:MM')} - {l.text}
            </p>
          ))}
        </div>

        <div>
          <h3>Заказы</h3>
          <OrdersTable docs={userOrders} loading={ordersLoading} />
        </div>
      </Wrap>
    </Page>
  );
};

UserPage.propTypes = {
  match: PropTypes.object,
};

const Wrap = styled.div`
  padding-left: 40px;
`;

const UserInfo = styled.div`
  span {
    font-weight: 800;
  }
`;

export default withRouter(UserPage);
