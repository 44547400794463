import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';

import { Button } from 'core/ui/atoms';
import { CREATE_STAGE } from '../../api/apollo/mutations';
import { LIST_STAGES } from '../../api/apollo/queries';

class CreateTimeline extends PureComponent {
  render() {
    return (
      <Cont>
        <Mutation mutation={CREATE_STAGE} refetchQueries={[{ query: LIST_STAGES }]}>
          {mutate => (
            <Button onClick={mutate}>+ Создать элемент</Button>
          )}
        </Mutation>
      </Cont>
    );
  }
}

const Cont = styled.div``;

export default CreateTimeline;
