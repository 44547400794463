import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SvgIcon, Input } from 'core/ui/atoms';

class Search extends PureComponent {
  render() {
    const { searchValue, onSearchValueChange } = this.props;
    return (
      <Wrap>
        <SvgIcon.Search />
        <SearchInput
          placeholder="Поиск по..."
          value={searchValue}
          onChange={onSearchValueChange}
        />
      </Wrap>
    );
  }
}

Search.propTypes = {
  searchValue: PropTypes.string,
  onSearchValueChange: PropTypes.func,
}

export default Search;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 7px;
  margin-top: 15px;
`;
const SearchInput = styled(Input)`
  font-family: Inter;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  background: none;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  font-size: ${p => p.theme.font.size.small};
  color: #B8B8B8;
`;
