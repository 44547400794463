import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { Box, Input } from 'core/ui/atoms';
import { EditHeading, EditBlock } from 'days/ui/atoms';

import withUpdateDay from 'days/ui/withUpdateDay';

@withUpdateDay
class DayQuote extends PureComponent {
  static propTypes = {
    quote: PropTypes.string,
    updateDay: PropTypes.func,
  };

  state = {
    quote: this.props.quote || '',
  };

  save = debounce(() => {
    const { quote } = this.state;
    this.props.updateDay({ quote });
  }, 1000);

  handleChange = (e) => {
    this.setState({ quote: e.target.value });
    this.save();
  };

  render() {
    const { quote } = this.state;
    return (
      <EditBlock>
        <Box width="100%">
          <EditHeading>Цитата:</EditHeading>
          <Input type="text" value={quote} onChange={this.handleChange}/>
        </Box>
      </EditBlock>
    );
  }
}

export default DayQuote;

