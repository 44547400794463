import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from 'core/ui/atoms';
import Loading from 'core/ui/components/Loading';
import UsersTableRow from './UsersTableRow';

class UsersTable extends PureComponent {
  static propTypes = {
    docs: PropTypes.array,
    loading: PropTypes.bool,
  };

  render() {
    const { docs, loading } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Имя пользователя</TableCell>
            <TableCell>Часовой пояс</TableCell>
            <TableCell>Завершено медитаций</TableCell>
            <TableCell>Последняя активность</TableCell>
            <TableCell>Дата регистрации</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
          && <Loading/>
          || docs.map((user) => <UsersTableRow key={user._id} user={user}/>)
          }
        </TableBody>
      </Table>
    );
  }
}

export default UsersTable;
