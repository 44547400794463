import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import BMF from 'browser-md5-file';
import compose from 'lodash/flowRight';

import { ButtonEdit, ContentText } from 'days/ui/atoms';
import { Box, SvgIcon, Spinner, Flex } from 'core/ui/atoms';
import { EditHeading } from 'days/ui/atoms';
import DayAudio from './DayAudio';
import { timeFormat } from 'days/api/helpers';
import { UPLOAD_FILE } from 'days/api/apollo/mutations';
import { ADD_PRACTICE_AUDIO } from 'days/api/apollo/mutations';
import { ADD_PRACTICE_NIGHT_AUDIO } from 'nights/api/apollo/mutations';
import { SINGLE_DAY, LIST_DAY } from 'days/api/apollo/queries';
import { SINGLE_NIGHT, LIST_NIGHTS } from 'nights/api/apollo/queries';
import { CLOUDFRONT_URL } from 'core/api/constants';
import { LIST_PRACTICES } from '../../../tracker/api/apollo/queries';
import { DataStore } from 'apollo-client/data/store';

const bmf = new BMF();

@withRouter
@compose(
  graphql(UPLOAD_FILE, { name: 'uploadFile' }),
  graphql(ADD_PRACTICE_AUDIO, { name: 'addPracticeAudio' }),
  graphql(ADD_PRACTICE_NIGHT_AUDIO, { name: 'addPracticeNightAudio' })
)
class UploadAudio extends PureComponent {
  static propTypes = {
    uploadFile: PropTypes.func,
    addPracticeAudio: PropTypes.func,
    addPracticeNightAudio: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        day: PropTypes.number,
        locale: PropTypes.string,
      }),
    }),
    audio: PropTypes.shape({
      url: PropTypes.string,
      time: PropTypes.number,
      name: PropTypes.string,
    }),
    part: PropTypes.string,
    isNight: PropTypes.bool,
  };

  state = {
    loading: false,
  };

  input = null;

  editAudio = () => this.input.click();

  getRef = (node) => (this.input = node);

  handleChange = (e) => {
    const {
      uploadFile,
      addPracticeAudio,
      addPracticeNightAudio,
      match: {
        params: { day: d, night: n, locale },
      },
      part,
      isNight,
    } = this.props;
    const day = Number(d);
    const night = Number(n);
    const [audio] = e.currentTarget.files;
    const reader = new FileReader();
    const upload = part ? isNight ? addPracticeNightAudio : addPracticeAudio : uploadFile;
    if (!audio) {
      return;
    }
    this.setState({ loading: true });
    reader.onload = (event) => {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      audioContext.decodeAudioData(event.target.result, (buffer) => {
        bmf.md5(
          audio,
          (err, md5) => {
            const data = {
              locale,
              audio,
              checksum: md5,
              time: Math.round(buffer.duration),
            };
            if (isNight) {
              data.night = night;
            } else {
              data.day = day;
            }
            if (part) {
              data.part = part;
            }
            upload({
              variables: { data },
              refetchQueries: isNight
                ? [
                  { query: SINGLE_NIGHT, variables: { night } },
                  { query: LIST_NIGHTS },
                ]
                : [
                  { query: SINGLE_DAY, variables: { day } },
                  { query: LIST_DAY },
                ],
            })
              .then(() => {
                this.input.value = '';
                this.setState({ loading: false });
              })
              .catch((err) => {
                console.log(JSON.stringify(err));
                this.input.value = '';
                this.setState({ loading: true });
              });
          },
          (progress) => {
            console.log('progress number:', progress);
          }
        );
      });
    };
    reader.onerror = (event) => {
      console.error('An error ocurred reading the file: ', event);
    };
    reader.readAsArrayBuffer(audio);
  };

  render() {
    const {
      audio: { path, name, time },
      part,
    } = this.props;
    return (
      <Flex>
        <Input
          ref={this.getRef}
          type='file'
          accept='audio/*'
          onChange={this.handleChange}
        />
        <Box>
          <ButtonEdit
            icon={<SvgIcon.DetailMusic />}
            small
            onClick={this.editAudio}
          />
        </Box>
        {(this.state.loading && <Spinner />) || (
          <Box width='30%'>
            <EditHeading>Аудиозапись:</EditHeading>
            <DayAudio
              time={time}
              src={`${CLOUDFRONT_URL}${path}`}
              full
              noUpdate={!!part}
            />
          </Box>
        )}
        <Box padded>
          <ContentText bold margin={3}>
            {name}
          </ContentText>
          <ContentText bold margin={10}>
            {timeFormat(time)}
          </ContentText>
        </Box>
      </Flex>
    );
  }
}

export default UploadAudio;

const Input = styled.input`
  display: none;
`;
