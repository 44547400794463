import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import {
  Page,
  PageTitle,
  Card,
} from 'core/ui/atoms';
import withStore from 'core/api/storeContext';
import ErrorOccurred from 'core/ui/components/ErrorOccurred';
import UsersTable from '../components/UsersTable';

import { PENDING_USERS } from 'users/api/apollo/queries';

@withStore
class PendingUsersPage extends PureComponent {
  render() {
    return (
      <Page>
        <PageTitle/>
        <Query query={PENDING_USERS}>
          {({ data, loading, error }) => {
            if (error) {
              return <ErrorOccurred error={error}/>;
            }
            const { pendingUsers } = data || {};
            return (
              <Card>
                <UsersTable loading={loading} docs={pendingUsers}/>
              </Card>
            );
          }}
        </Query>
      </Page>
    );
  }
}

export default PendingUsersPage;
