import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class CheckoutItem extends PureComponent {
  static propTypes = {
    checkout: PropTypes.object,
  };

  render() {
    const { checkout: { title, checkoutData: { email }, status } } = this.props;
    return (
      <Cont>
        <div>
          <span>{email}</span>
        </div>
        <div>
          <span>{title}</span>
        </div>
        <div>
          <span>{status}</span>
        </div>
      </Cont>
    );
  }
}

const Cont = styled.div`
  display: flex;
  padding: 9px 0;
  border-bottom: 1px solid #f6f6f6;
  > div {
    padding-right: 13px;
    &:nth-child(1) {
      width: 239px;
      > span {
        font-weight:bold;
      }
    }
    &:nth-child(2) {
      flex-grow: 1;
    }
  }
`;

export default CheckoutItem;
