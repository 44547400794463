import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';

import {
  Flex,
} from 'core/ui/atoms';
import { OperationHeading, OperationCard, OperationButton } from 'operations/ui/atoms';
import { RECALCULATE_MRR } from 'orders/api/apollo/mutations';
import { withInAppNotification } from 'core/api/toast/withInAppNotification';

@graphql(RECALCULATE_MRR)
@withInAppNotification
class RecalculateMrr extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func,
    showNotification: PropTypes.func,
  };

  state = {
    isProcessing: false,
  };

  setValue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  recalculate = async () => {
    const {isProcessing } = this.state;
    if (isProcessing) {
      return;
    }
    try {
      const { data: { recalculateMrr } } = await this.props.mutate();
      console.log('recalculateMrr', recalculateMrr);
      this.setState({ isProcessing: false });
    } catch(e) {
      console.log(e);
      this.setState({ isProcessing: false });
    }
  };

  render() {
    const { isProcessing } = this.state;
    return (
      <OperationCard>
        <OperationHeading>
          Пересчитать MRR
        </OperationHeading>
        <OperationFlex>
          <OperationButton disabled={isProcessing} onClick={this.recalculate}>Пересчитать</OperationButton>
        </OperationFlex>
      </OperationCard>
    );
  }
}

const OperationFlex = styled(Flex)`
  input {
    margin: 0 5px;
    &[type="number"] {
      width: 130px;
    }
  }
`;

export default RecalculateMrr;
