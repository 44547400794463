// Layouts
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import Retreat from 'retreat/ui/pages/Retreat';

export default [
  {
    component: Retreat,
    layout: AuthenticatedLayout,
    path: '/retreat',
    exact: true,
  },
];
