import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import {
  Page,
  PageTitle,
  Button,
  Heading,
  Flex,
  Card,
} from 'core/ui/atoms';
import {
  LIST_PRACTICES,
  LIST_SHORT_PRACTICES,
  LIST_MUSIC,
  LIST_DREAM_PRACTICES
} from '../../api/apollo/queries';
import ErrorOccurred from '../../../core/ui/components/ErrorOccurred';
import Loading from '../../../core/ui/components/Loading';
import PracticeItem from '../components/PracticeItem';
import MusicItem from '../components/MusicItem';
import AddMusic from '../components/AddMusic';
import AddShortPractice from '../components/AddShortPractice';
import AddShortPracticeModal from '../components/AddShortPracticeModal';
import AddDreamPracticeModal from '../components/AddDreamPracticeModal';
import AddDreamPractice from '../components/AddDreamPractice';
import DreamPracticeItem from '../components/DreamPracticeItem';
import EditMusicModal from '../components/EditMusicModal';

class Tracker extends PureComponent {
  state = {
    active: 'ru',
    modalData: null,
    isShortPracticeModalVisible: false,
    isDreamPracticeModalVisible: false,
    dreamType: 'THEORY'
  };

  changeLocale = () => {
    this.setState(s => ({ active: s.active === 'ru' ? 'en' : 'ru' }));
  };

  showModal = modalData => {
    this.setState({ modalData });
  };

  closeModal = () => {
    this.setState({ modalData: null });
  };

  toggleShortPracticeModal = () => {
    this.setState({
      isShortPracticeModalVisible: !this.state.isShortPracticeModalVisible,
    });
  };

  toggleDreamPracticeModal = (type = 'THEORY') => {
    this.setState({
      isDreamPracticeModalVisible: !this.state.isDreamPracticeModalVisible,
      dreamType: type,
    });
  };

  render() {
    const { active, modalData } = this.state;
    return (
      <Page>
        <PageTitle>Практика</PageTitle>
        <Flex>
          <CustomButton onClick={this.changeLocale} disabled={active === 'ru'}>Русский</CustomButton>
          <CustomButton onClick={this.changeLocale} disabled={active === 'en'}>Английский</CustomButton>
        </Flex>
        <Content>
          <Heading>Практики</Heading>
          <Query query={LIST_PRACTICES}>
            {({ error, loading, data }) => {
              if (error) {
                console.log('error', JSON.stringify(error));
                return <ErrorOccurred error={error}/>;
              }
              if (loading) {
                return <Loading/>;
              }
              return (
                <Flex wrap="wrap">
                  {(data || {}).listPractices.map(p => <PracticeItem key={p._id} practice={p} locale={active} />)}
                </Flex>
              )
            }}
          </Query>
          <AddDreamPracticeModal
            isVisible={this.state.isDreamPracticeModalVisible}
            toggleDreamPracticeModal={this.toggleDreamPracticeModal}
            type={this.state.dreamType}
          />
          <Heading>Короткие практики</Heading>
          <Query query={LIST_SHORT_PRACTICES}>
            {({ error, loading, data }) => {
              if (error) {
                console.log('error', JSON.stringify(error));
                return <ErrorOccurred error={error} />;
              }
              if (loading) {
                return <Loading />;
              }
              return (
                <Flex wrap="wrap">
                  {(data || {}).listShortPractices.map(p => <PracticeItem key={p._id} practice={p} locale={active} />)}
                  <AddShortPractice toggleShortPracticeModal={this.toggleShortPracticeModal} />
                  <AddShortPracticeModal
                    isVisible={this.state.isShortPracticeModalVisible}
                    toggleShortPracticeModal={this.toggleShortPracticeModal}
                  />
                </Flex>
              )
            }}
          </Query>
          <Heading>Практики Сон</Heading>
          <Heading level={5}>Теория</Heading>
          <Query query={LIST_DREAM_PRACTICES} variables={{ type: 'THEORY' }}>
            {({ error, loading, data }) => {
              if (error) {
                console.log('error', JSON.stringify(error));
                return <ErrorOccurred error={error} />;
              }
              if (loading) {
                return <Loading />;
              }
              return (
                <Flex wrap="wrap">
                  {(data || {}).listDreamPractices.map(p => (
                    <DreamPracticeItem
                      key={p._id}
                      practice={p}
                      locale={active}
                      dreamType={this.state.dreamType}
                    />
                  ))}
                  <AddDreamPractice
                    toggleDreamPracticeModal={this.toggleDreamPracticeModal}
                    type={'THEORY'}
                  />
                </Flex>
              )
            }}
          </Query>
          <Heading level={5}>Практика</Heading>
          <Query query={LIST_DREAM_PRACTICES} variables={{ type: 'PRACTICE' }}>
            {({ error, loading, data }) => {
              if (error) {
                console.log('error', JSON.stringify(error));
                return <ErrorOccurred error={error} />;
              }
              if (loading) {
                return <Loading />;
              }
              return (
                <Flex wrap="wrap">
                  {(data || {}).listDreamPractices.map(p => (
                    <DreamPracticeItem
                      key={p._id}
                      practice={p}
                      locale={active}
                      dreamType={this.state.dreamType}
                    />
                  ))}
                  <AddDreamPractice
                    toggleDreamPracticeModal={this.toggleDreamPracticeModal}
                    type={'PRACTICE'}
                  />
                </Flex>
              )
            }}
          </Query>
          <Heading level={5}>Истории</Heading>
          <Query query={LIST_DREAM_PRACTICES} variables={{ type: 'STORY' }}>
            {({ error, loading, data }) => {
              if (error) {
                console.log('error', JSON.stringify(error));
                return <ErrorOccurred error={error} />;
              }
              if (loading) {
                return <Loading />;
              }
              return (
                <Flex wrap="wrap">
                  {(data || {}).listDreamPractices.map(p => (
                    <DreamPracticeItem
                      key={p._id}
                      practice={p}
                      locale={active}
                      dreamType={this.state.dreamType}
                    />
                  ))}
                  <AddDreamPractice
                    toggleDreamPracticeModal={this.toggleDreamPracticeModal}
                    type={'STORY'}
                  />
                </Flex>
              )
            }}
          </Query>
          <Heading>Музыка</Heading>
          <Query query={LIST_MUSIC}>
            {({ error, loading, data }) => {
              if (error) {
                console.log('error', JSON.stringify(error));
                return <ErrorOccurred error={error} />;
              }
              if (loading) {
                return <Loading />;
              }
              return (
                <Flex wrap="wrap">
                  {(data || {}).listMusic.map(m => (
                    <MusicItem
                      key={m._id}
                      locale={active}
                      showModal={this.showModal}
                      {...m}
                    />
                  ))}
                  <AddMusic />
                </Flex>
              )
            }}
          </Query>
        </Content>
        {modalData && <EditMusicModal modalData={modalData} onClose={this.closeModal} />}
      </Page>
    );
  }
}

const CustomButton = styled(Button)`
  padding: 10px 20px;
  margin: 0 15px 15px 0;
`;

export default Tracker;

const Content = styled.div`
  margin: 0 15px 15px 0;
`;
