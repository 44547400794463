import React from 'react';
import styled, { css } from 'styled-components';

import { Box } from './';

const Card = styled(Box)`
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-bottom: 13px;
  ${p => p.small && css`
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
  `}
  ${p => p.padded && css`
    padding: 20px;
  `}
  > h4 {
    margin-top: 0;
  }
`;

export default Card;

