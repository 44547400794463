import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Card, Heading, Flex, Spinner } from '../../../core/ui/atoms';
import { MetricItem } from '../../../dashboard/ui/atoms';

class ParticipantsByDate extends PureComponent {
  static propTypes = {
    groupByDate: PropTypes.object,
  };

  render() {
    const { groupByDate } = this.props;
    return (
      <Card padded>
        <Heading>По датам:</Heading>
        <Flex>
          {Object.entries(groupByDate).map(([date, c]) => (
            <MetricItem key={date} title={date} isLoading={false}>
              {c.filter(c => c.status === 'PAID').length}
            </MetricItem>
          ))}
        </Flex>
      </Card>
    );
  }
}

export default ParticipantsByDate;
