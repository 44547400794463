import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chart from 'chart.js';

import { Spinner } from '../../../core/ui/atoms';
import { createGraphRange, getDataset, getGraphConfig } from '../../api/helpers';
import { theme } from '../../../core/ui/theme';
import groupBy from 'lodash/groupBy';

let myChart = null;

const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = 499;

class MoneyGraph extends PureComponent {
  static propTypes = {
    result: PropTypes.object,
    range: PropTypes.string,
    graphType: PropTypes.string,
  };

  componentDidMount() {
    this.initGraph();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.result.loading && !this.props.result.loading ||
      prevProps.range !== this.props.range ||
      prevProps.graphType !== this.props.graphType
    ) {
      this.initGraph();
    }
  }

  initGraph = () => {
    if (this.props.result.loading) {
      return;
    }
    if (myChart) {
      myChart.destroy();
    }
    const range = createGraphRange(this.props.range);
    const labels = range.map(r => r.name);
    const ctx = document.getElementById('money-chart');
    ctx.width = GRAPH_WIDTH;
    ctx.height = GRAPH_HEIGHT;
    const { data: { getMetricsMonetizeGraph } } = this.props.result;
    const sources = Object.keys(groupBy(getMetricsMonetizeGraph, i => i.sourceGroup)).filter(s => s !== 'null');
    const groupByDate = range.map((r, i) => {
      if (!range[i + 1]) {
        return getMetricsMonetizeGraph.filter(item => item.createdAt > r.isoDate);
      }
      return getMetricsMonetizeGraph.filter(item => item.createdAt > r.isoDate && item.createdAt < range[i + 1].isoDate);
    });
    const datasets = (() => {
      if (this.props.graphType === 'DEVICES') {
        return ['IOS', 'ANDROID'].map((device, i) => {
          const data = (() => {
            return groupByDate.map(g => g.filter(item => item.device === device).length);
          })();
          return getDataset(device, data, theme.graphColors[i] || theme.graphColors[0]);
        });
      }
      return ['total', ...sources].map((source, i) => {
        const data = (() => {
          if (source === 'total') {
            return groupByDate.map(g => g.length);
          }
          return groupByDate.map(g => g.filter(item => item.sourceGroup === source).length);
        })();
        return getDataset(source, data, theme.graphColors[i] || theme.graphColors[0]);
      });
    })();
    const config = getGraphConfig(labels, datasets);
    myChart = new Chart(ctx, config);
  };

  render() {
    const { result: { loading } } = this.props;
    if (loading) {
      return (
        <GraphLoading>
          <Spinner />
        </GraphLoading>
      );
    }
    return (
      <Canvas id={'money-chart'} />
    );
  }
}

const GraphLoading = styled.div`
  width: 499px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f6;
  border-radius: 6px;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 400px;
  margin-right: 5px;
`;

export default MoneyGraph;
