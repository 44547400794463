import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, SvgIcon, Card } from 'core/ui/atoms';

class AddShortPractice extends PureComponent {
  static propTypes = {
    toggleShortPracticeModal: PropTypes.func,
  };

  render() {
    return (
      <Wrap md={4} sm={12} xs={12} onClick={this.props.toggleShortPracticeModal}>
        <Card>
          <SvgIcon.Plus />
          <Caption>Добавить практику</Caption>
        </Card>
      </Wrap>
    );
  }
}

const Wrap = styled(Box)`
  position: relative;
  display: flex;
  padding: 5px;
  cursor: pointer;
  > div {
    width: 100%;
    padding: 20px 13px;
    display:flex;
  }
  svg {
    position:relative;
    top: 3px;
    left: 6px;
    transform: scale(1.3);
    color: ${p => p.theme.colors.primary}
  }
`;

const Caption = styled.h5`
   margin: 0;
   padding-left: 23px;
`;

export default AddShortPractice;
