// Layouts
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import Tracker from 'tracker/ui/pages/Tracker';
import PracticeContent from 'tracker/ui/pages/PracticeContent';

export default [
  {
    component: Tracker,
    layout: AuthenticatedLayout,
    path: '/tracker',
    exact: true,
  },
  {
    component: PracticeContent,
    layout: AuthenticatedLayout,
    path: '/tracker/:locale/:_id'
  },
];
