import React from 'react';
import styled from 'styled-components';

import { Flex } from 'core/ui/atoms';

const EditBlock = styled(Flex)`
  margin-bottom: 15px;
`;

export default EditBlock;
