import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import debounce from 'lodash/debounce';

import { Page, PageTitle, Card, Button } from 'core/ui/atoms';
import withStore from 'core/api/storeContext';
import ErrorOccurred from 'core/ui/components/ErrorOccurred';
import UsersTable from '../components/UsersTable';
import UsersTableControls from '../components/UsersTableControls';
import history from 'core/api/history';

import { LIST_USER } from 'users/api/apollo/queries';

@withStore
class NewUsersPage extends PureComponent {
  static propTypes = {
    setStore: PropTypes.func,
    tableNewUsers: PropTypes.shape({
      sort: PropTypes.string,
      count: PropTypes.number,
      page: PropTypes.number,
      search: PropTypes.string,
    }),
  };

  static defaultProps = {
    tableNewUsers: {
      sort: 'LAST_ACTIVE',
      count: 20,
      page: 0,
      search: '',
    },
  };

  openCurrentDays = () => {
    history.push('/users/current-days');
  };

  updateStore = (state) => {
    const { tableNewUsers } = this.props;
    this.props.setStore({ tableNewUsers: { ...tableNewUsers, ...state } });
  };

  updateStoreDebounce = debounce((state) => {
    const { tableNewUsers } = this.props;
    this.props.setStore({ tableNewUsers: { ...tableNewUsers, ...state } });
  }, 1000);

  render() {
    const { sort, count, page, search } = this.props.tableNewUsers;
    return (
      <Page>
        <Button unstyled outline onClick={this.openCurrentDays}>Текущие дни</Button>
        <PageTitle />
        <Query query={LIST_USER} variables={{ sort, count, page, search }}>
          {({ data, loading, error }) => {
            if (error) {
              return <ErrorOccurred error={error} />;
            }
            const { adminUserList: { docs = [], total = 0 } = {} } = data || {};
            return (
              <Card>
                <UsersTableControls
                  total={500}
                  update={this.updateStore}
                  updateDebounce={this.updateStoreDebounce}
                  {...this.props.tableNewUsers}
                />
                <UsersTable loading={loading} docs={docs} />
              </Card>
            );
          }}
        </Query>
      </Page>
    );
  }
}

export default NewUsersPage;
