import gql from 'graphql-tag';

import { dayFragment } from './fragments';

export const UPLOAD_FILE = gql`
  mutation adminUpdateDayUpload($data: AdminDayUploadInput) {
    adminUpdateDayUpload(data: $data) {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;

export const UPDATE_DAY = gql`
  mutation adminUpdateDay($data: AdminDayInput) {
    adminUpdateDay(data: $data) {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;

export const UPDATE_NOTIFICATION = gql`
  mutation adminDayNotificationUpdate($data: AdminDayNotificationInput) {
    adminDayNotificationUpdate(data: $data) {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;

export const REMOVE_NOTIFICATION = gql`
  mutation adminDayNotificationRemove($data: AdminDayNotificationInput) {
    adminDayNotificationRemove(data: $data) {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;

export const ADD_PRACTICE_AUDIO = gql`
  mutation adminAddPracticeAudio($data: AdminAddPracticeAudioInput!) {
    adminAddPracticeAudio(data: $data) {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;

export const ADMIN_ADD_DAY_AUDIO_PAD = gql`
  mutation adminAddDayAudioPad($data: AdminAddAudioPadInput!) {
    adminAddDayAudioPad(data: $data) {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;

export const REMOVE_SUPPORT_AUDIO = gql`
  mutation removeSupportAudio($data: RemoveSupportAudioInput!) {
    removeSupportAudio(data: $data) {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;

export const ADD_DAY = gql`
  mutation adminAddDay {
    adminAddDay {
      ...AdminDayData
    }
  }
  ${dayFragment}
`;