import gql from 'graphql-tag';

export const listUserData = gql`
  fragment ListUserData on User {
    _id
    personalData {
      firstName
      email
      timezone
    }
    serviceData {
      currentDay
      locale
    }
    createdAt
    lastActiveAt
  }
`;

export const singleUserData = gql`
  fragment SingleUserData on User {
    _id
    personalData {
      firstName
      email
      timezone
    }
    serviceData {
      notifications {
        send
        token
      }
      currentDay
      locale
      isSubscribed
      expirationDate
      expirationDateWeb
      forceSubscribed
      recurrentPayment
      mrr
      currentStreak
      maxStreak
    }
    createdAt
    lastActiveAt
  }
`;