import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Box, Card as CardAtom, SvgIcon, Flex, Heading } from 'core/ui/atoms';
import { ButtonEdit, ContentText } from 'days/ui/atoms';
import NotificationModal from './NotificationModal';

class NotificationItem extends PureComponent {
  static propTypes = {
    notification: PropTypes.shape({
      time: PropTypes.string,
      text: PropTypes.string,
      _id: PropTypes.string,
    }),
  };

  state = {
    show: false,
  };

  toggleModal = () => {
    this.setState(st => ({ show: !st.show }));
  };

  render() {
    const { time, text, _id } = this.props.notification;
    return (
      <Wrap md={4} sm={12} xs={12}>
        <Card small>
          <Flex alignItems="center" justifyContent="space-between">
            <TimeWrap>
              <SvgIcon.DetailClock/>
              <Heading noMargin level={5}>
                {time || '- - : - -'}
              </Heading>
            </TimeWrap>
            <Box>
              <ContentText>
                {text || 'Текст уведомеления будет здесь...'}
              </ContentText>
            </Box>
            <Box>
              <ButtonEdit small onClick={this.toggleModal}/>
            </Box>
          </Flex>
        </Card>
        {this.state.show && (
          <NotificationModal
            onClose={this.toggleModal}
            time={time}
            text={text}
            _id={_id}
          />
        )}
      </Wrap>
    );
  }
}

export default NotificationItem;

const Wrap = styled(Box)`
  padding: 10px;
`;

const Card = styled(CardAtom)`
  padding: 10px;
`;

const TimeWrap = styled(Box)`
  text-align: center;
  h5 {
    color: ${p => p.theme.colors.primary};
  }
`;

