export const GRAPH_RANGES = [
  { title: 'Неделя', value: 'WEEK' },
  { title: 'Месяц', value: 'MONTH' },
  { title: '3 месяца', value: 'THREE_MONTH' },
];

export const PRECISE_RANGES = [
  { title: 'Сегодня', value: 'TODAY' },
  { title: 'Вчера', value: 'YESTERDAY' },
  { title: 'Неделя', value: 'WEEK' },
  { title: 'Месяц', value: 'MONTH' },
  { title: '3 месяца', value: 'THREE_MONTH' },
  { title: 'Все время', value: 'ALL' },
];

export const GRAPH_TYPES = [
  { title: 'По источникам', value: 'SOURCES' },
  { title: 'По устройствам', value: 'DEVICES' },
];

export const PLATFORMS = [
  { title: 'Все устройства', value: 'ALL' },
  { title: 'iOS', value: 'IOS' },
  { title: 'Android', value: 'ANDROID' },
];

export const IOS_PRODUCT_IDS_LIST = [
  'com.microcosm.sati.donation1',
  'com.microcosm.sati.donation2',
  'com.microcosm.sati.donation3',
  // 'com.microcosm.sati.donation4',
  'com.microcosm.sati.donation5',
  'com.microcosm.sati.donation6',
  'com.microcosm.sati.donation7',
  'com.microcosm.sati.donation8',
  'com.microcosm.sati.donation9',
  'com.microcosm.sati.donation10',
  'com.microcosm.sati.donation11',
  'com.microcosm.sati.donation12',
  'com.microcosm.sati.donation13',
  'com.microcosm.sati.donation14',
  'com.microcosm.sati.donation15',
  'com.microcosm.sati.donation16',
  'com.microcosm.sati.donation17',
  'com.microcosm.sati.donation18',
  'com.microcosm.sati.donation19',
];


export const PURCHASES = {
  'com.microcosm.sati.donation1': 3.99,
  'com.microcosm.sati.donation2': 4.99,
  'com.microcosm.sati.donation3': 5.99,
  'com.microcosm.sati.donation4': 6.99,
  'com.microcosm.sati.donation5': 7.99,
  'com.microcosm.sati.donation6': 9.99,
  'com.microcosm.sati.donation7': 12.99,
  'com.microcosm.sati.donation8': 15.99,
  'com.microcosm.sati.donation9': 20.99,
  'com.microcosm.sati.donation10': 25.99,
  'com.microcosm.sati.donation11': 30.99,
  'com.microcosm.sati.donation12': 40.99,
  'com.microcosm.sati.donation13': 49.99,
  'com.microcosm.sati.donation14': 74.99,
  'com.microcosm.sati.donation15': 99.99,
  'com.microcosm.sati.donation16': 149.99,
  'com.microcosm.sati.donation17': 199.99,
  'com.microcosm.sati.donation18': 249.99,
  'com.microcosm.sati.donation19': 299.99,
};
