import React, { PureComponent } from 'react';
import styled from 'styled-components';

import {
  Page,
  PageTitle,
} from 'core/ui/atoms';
import SendToDay from 'operations/ui/components/SendToDay';
import MaxStreak from 'operations/ui/components/MaxStreak';
import GiveFreeCourse from 'operations/ui/components/GiveFreeCourse';
import SendToNight from 'operations/ui/components/SendToNight';
import CloneUserDays from 'operations/ui/components/CloneUserDays';
import RecalculateMrr from 'operations/ui/components/RecalculateMrr';
import RecalculateSubscriptions from 'operations/ui/components/RecalculateSubscriptions';
import CancelSubscriptions from 'operations/ui/components/CancelSubscriptions';

class Operations extends PureComponent {
  render() {
    return (
      <Page>
        <PageTitle>Операции</PageTitle>
        <Content>
          <SendToDay />
          <SendToNight />
          <GiveFreeCourse />
          <MaxStreak />
          <CancelSubscriptions />
          {/* <CloneUserDays /> */}
          {/* <RecalculateMrr /> */}
          {/* <RecalculateSubscriptions /> */}
        </Content>
      </Page>
    );
  }
}

export default Operations;

const Content = styled.div`
  margin: 0 15px 15px 0;
`;

