import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import { Box } from 'core/ui/atoms';
import { ImageUpload } from 'days/ui/atoms';
import DEFAULT_IMAGE from 'days/assets/default-image.png';
import PhoneControls from './PhoneControls';
import { UPLOAD_FILE } from 'days/api/apollo/mutations';
import { UPLOAD_FILE_NIGHT } from 'nights/api/apollo/mutations';
import { SINGLE_DAY, LIST_DAY } from 'days/api/apollo/queries';
import { SINGLE_NIGHT, LIST_NIGHTS } from 'nights/api/apollo/queries';
import { CLOUDFRONT_URL } from 'core/api/constants';

@withRouter
@graphql(UPLOAD_FILE, { name: 'uploadFile' })
@graphql(UPLOAD_FILE_NIGHT, { name: 'uploadFileNight' })
class MainImage extends PureComponent {
  static propTypes = {
    uploadFile: PropTypes.func,
    uploadFileNight: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        day: PropTypes.number,
        locale: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
      path: PropTypes.string,
    }),
    instagramImage: PropTypes.shape({
      url: PropTypes.string,
      path: PropTypes.string,
    }),
    title: PropTypes.string,
    day: PropTypes.string,
    night: PropTypes.string,
    isNight: PropTypes.bool,
  };

  state = {
    loading: false,
    instaLoading: false,
  };

  handleChange = (e) => {
    const { uploadFile, uploadFileNight, match: { params: { day: d, night: n, locale } }, isNight } = this.props;
    const day = Number(d);
    const night = Number(n);
    const [image] = e.currentTarget.files;
    if (!image) {
      return;
    }
    this.setState({ loading: true });
    const data = isNight ? { night, locale, image } : { day, locale, image };
    const upload = isNight ? uploadFileNight : uploadFile;
    upload({
      variables: { data },
      refetchQueries: isNight ? [
        { query: SINGLE_NIGHT, variables: { night } },
        { query: LIST_NIGHTS },
      ] : [
        { query: SINGLE_DAY, variables: { day } },
        { query: LIST_DAY },
      ],
    }).then(() => {
      this.setState({ loading: false });
    }).catch(() => {
      console.log(e);
      this.setState({ loading: true });
    })
  };

  handleInstaChange = (e) => {
    const { uploadFile, match: { params: { day: d, locale } } } = this.props;
    const day = Number(d);
    const [image] = e.currentTarget.files;
    if (!image) {
      return;
    }
    this.setState({ loading: true });
    uploadFile({
      variables: { data: { day, locale, image } },
      refetchQueries: [
        { query: SINGLE_DAY, variables: { day } },
        { query: LIST_DAY },
      ],
    }).then(() => {
      this.setState({ loading: false });
    }).catch(() => {
      console.log(e);
      this.setState({ loading: true });
    })
  };

  render() {
    const { image: { path }, title, day, night, isNight } = this.props;
    const src = path ? `${CLOUDFRONT_URL}${path}` : DEFAULT_IMAGE;
    return (
      <ImageBox>
        <Image src={src}/>
        <PhoneOverlay title={title} day={day} night={night} isNight={isNight} />
        <ImageUpload loading={this.state.loading} onChange={this.handleChange}/>
        <DownloadButton href={src} download>Скачать</DownloadButton>
      </ImageBox>
    );

  }
}

const ImageBox = styled(Box)`
  position: relative;
  width: 233px;
  height: 466px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 20px;
`;

const Image = styled.div` 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  margin-right: 25px;
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center;
`;

const PhoneOverlay = styled(PhoneControls)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const DownloadButton = styled.a`
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background: none;
  border: 0;
  cursor: pointer;
`;

export default MainImage;
