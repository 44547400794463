import styled from 'styled-components';

const OperationHeading = styled.h4`
  color: ${p => p.theme.colors.gray.dark};
  margin-top: 0;
  font-size: 23px;
  margin-bottom: 16px;
  span {
    font-size: 11px;
    font-weight: 400;
    color: #bbbbbb;
    margin-left: 13px;
  }
`;

export default OperationHeading;
