import gql from 'graphql-tag';

import { stageFragment } from './fragments';

export const CREATE_STAGE = gql`
  mutation createStage {
    createStage {
      ...StageFull
    }
  }
  ${stageFragment}
`;

export const REMOVE_STAGE = gql`
  mutation removeStage($_id: ID!) {
    removeStage(_id: $_id) {
      ...StageFull
    }
  }
  ${stageFragment}
`;

export const UPDATE_STAGE_DAYS = gql`
  mutation updateStageDays($_id: ID!, $days: Int!) {
    updateStageDays(_id: $_id, days: $days) {
      ...StageFull
    }
  }
  ${stageFragment}
`;

export const UPDATE_STAGE_CONTENT_TEXT = gql`
  mutation updateStageContentText($_id: ID!, $locale: String!, $name: String!, $value: String!) {
    updateStageContentText(_id: $_id, locale: $locale, name: $name, value: $value) {
      ...StageFull
    }
  }
  ${stageFragment}
`;

