import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';

import {
  Page,
  PageTitle,
  Card,
  Flex,
  Box,
  SvgIcon,
} from 'core/ui/atoms';
import MainImage from 'days/ui/components/MainImage';
import StoryImage from 'days/ui/components/StoryImage';
import NightName from 'nights/ui/components/NightName';
import NightText from 'nights/ui/components/NightText';
import NightQuote from 'nights/ui/components/NightQuote';
import NightAuthor from 'nights/ui/components/NightAuthor';
import UploadAudio from 'days/ui/components/UploadAudio';
import Loading from 'core/ui/components/Loading';
import UploadSupportAudio from 'days/ui/components/UploadSupportAudio';
import SilenceInput from 'tracker/ui/components/SilenceInput';
import SupportAudio from 'days/ui/components/SupportAudio';
import { SINGLE_NIGHT } from 'nights/api/apollo/queries';
import { ADMIN_ADD_NIGHT_AUDIO_PAD } from 'nights/api/apollo/mutations';
import ErrorOccurred from '../../../core/ui/components/ErrorOccurred';

const PARTS = [
  'theory',
  'practice',
  // 'support',
  // 'integration'
];

@withRouter
@compose(
  graphql(SINGLE_NIGHT, {
    options: ({ match: { params: { night } } }) => ({
      variables: { night: Number(night) },
    }),
    name: 'night',
  }),
  graphql(ADMIN_ADD_NIGHT_AUDIO_PAD),
)
class NightContentPage extends PureComponent {
  static propTypes = {
    night: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    mutate: PropTypes.func,
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { params: { night, locale } } = this.props.match;
    const { night: { adminNightSingle, loading, error }, mutate } = this.props;
    if (loading) {
      return <Loading/>;
    }
    if (error) {
      return <ErrorOccurred />;
    }
    const {
      image,
      name,
      description,
      instagramImage,
      quote,
      author,
      practice
    } = adminNightSingle.content[locale];
    return (
      <Page>
        <Flex alignItems="center">
          <Back onClick={this.goBack}><SvgIcon.ArrowLeft/></Back>
          <PageTitle>Список ночей / <DayTitle>Ночь {night}</DayTitle></PageTitle>
        </Flex>
        <Card padded>
          <Flex>
            <div>
              <MainImage image={image} title={name} night={night} isNight />
              <StoryImage image={instagramImage} isNight />
            </div>
            <RightSide>
              <NightName name={name} />
              <NightText description={description} />
              <NightQuote quote={quote} />
              <NightAuthor author={author} />
              <AudioContTitle>Полная запись</AudioContTitle>
              {PARTS.map(part => {
                const currentPractice = practice || {};
                // if (part === 'support') {
                //   return (
                //     <AudioCont key={`course-day-${part}`}>
                //       <AudioContTitle>{part}</AudioContTitle>
                //       {(currentPractice.support || []).map(audio => (
                //         <>
                //           <SupportAudio key={audio._id} locale={locale} day={night} {...audio} />
                //           <SilenceCont>
                //             <SilenceInput
                //               mutate={mutate}
                //               data={{ part, night: Number(night), locale, _id: audio._id }}
                //               pad={audio.pad}
                //             />
                //           </SilenceCont>
                //         </>
                //       ))}
                //       <UploadSupportAudio day={night} locale={locale} />
                //     </AudioCont>
                //   );
                // }
                return (
                  <>
                    <AudioCont key={`course-night-${part}`}>
                      <AudioContTitle>{part}</AudioContTitle>
                      <UploadAudio audio={currentPractice[part] || {}} part={part} isNight />
                    </AudioCont>
                    {part !== 'integration' ? (
                      <SilenceCont>
                        <SilenceInput
                          mutate={mutate}
                          data={{ part, night: Number(night), locale }}
                          pad={(currentPractice[part] || {}).pad || 0}
                        />
                      </SilenceCont>
                    ) : null}
                  </>
                )
              })}
            </RightSide>
          </Flex>
        </Card>
      </Page>
    );
  }
}

export default NightContentPage;

const Back = styled.div`
  margin-right: 10px;
  display: flex;
  cursor: pointer;
  color: ${p => p.theme.colors.primary};
`;

const DayTitle = styled.span`
  color: ${p => p.theme.colors.primary};
`;

const RightSide = styled(Box)`
  width: 100%;
`;

const FlexWrap = styled(p => <Flex wrap="wrap" {...p}/>)`
  margin: 20px -10px;
`;

const AudioCont = styled.div`
  margin-top: 30px;
`;

const AudioContTitle = styled.h6`
  text-transform: capitalize;
`;

const SilenceCont = styled.div`
  width: 300px;
  margin-top: 13px;
`;
