import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withInAppNotification } from 'core/api/toast/withInAppNotification';
import ToastContent from 'core/ui/components/ToastContent';
import { Flex } from 'core/ui/atoms';

@withInAppNotification
class Toast extends PureComponent {
  static propTypes = {
    notifications: PropTypes.object
  };

  render() {
    const { notifications } = this.props;

    return (
      <ToastCont direction={'column'}>
        {notifications.map(n => <ToastContent key={n._id} notification={n} />)}
      </ToastCont>
    )
  }
}

const ToastCont = styled(Flex)`
  position: fixed;
	width: 348px;
  top: 23px;
  right: 23px;
  z-index: 1080;
  display:flex;
  flex-direction: column;
  align-items: flex-end;
`;

export default Toast;
