import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { ADD_NIGHT } from 'nights/api/apollo/mutations';
import { LIST_NIGHTS } from 'nights/api/apollo/queries';

const withAddNight = Component => (
  @withRouter
  @graphql(ADD_NIGHT, { name: 'addNight' })
  class Wrapper extends PureComponent {
    static propTypes = {
      name: PropTypes.string,
      addNight: PropTypes.func,
      match: PropTypes.shape({
        params: PropTypes.shape({
          night: PropTypes.number,
          locale: PropTypes.string,
        }),
      }),
    };

    addNight = () => {
      const { addNight, match: { params: { night: n, locale } } } = this.props;
      return addNight({
        refetchQueries: [
          { query: LIST_NIGHTS }
        ]
      });
    };

    render() {
      const { addNight, ...rest } = this.props;
      return (
        <Component {...rest} addNight={this.addNight} />
      );
    }
  }
);

export default withAddNight;
