import gql from 'graphql-tag';

import { stageFragment } from './fragments';

export const LIST_STAGES = gql`
  query listStages {
    listStages {
      ...StageFull
    }
  }
  ${stageFragment}
`;
