import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';

import {
  Flex,
  Text,
  Input,
} from 'core/ui/atoms';
import { OperationHeading, OperationCard, OperationButton } from 'operations/ui/atoms';
import { ADMIN_MAX_STREAK } from 'operations/api/apollo/mutations';
import { withInAppNotification } from 'core/api/toast/withInAppNotification';

@graphql(ADMIN_MAX_STREAK)
@withInAppNotification
class MaxStreak extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func,
    showNotification: PropTypes.func,
  };

  state = {
    inProgress: false,
    _id: '',
    email: '',
    streak: 0,
  };

  setValue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  send = async () => {
    const { _id, email, streak } = this.state;
    if (!_id && !email) {
      return this.props.showNotification({
        title: 'Введите мейл или ID',
        message: 'Без этого никак :)',
        type: 'ERROR',
      });
    }
    if (!streak) {
      return this.props.showNotification({
        title: 'Укажите рекорд',
        message: 'Как по твоему машина догадается сколько дней? :)',
        type: 'ERROR',
      });
    }
    const { data: { adminMaxStreak } } = await this.props.mutate({
      variables: {
        email: this.state.email,
        _id: this.state._id,
        streak: Number(this.state.streak)
      }
    });
    if (adminMaxStreak) {
      this.setState({
        _id: '',
        email: '',
        streak: 0,
      });
      return this.props.showNotification({
        title: 'Ура!',
        message: 'Пользователь теперь на нужном дне',
        type: 'SUCCESS',
      });
    } else {
      return this.props.showNotification({
        title: 'Что-то не так',
        message: 'Напишите разработчикам, опять какой-то косяк',
        type: 'ERROR',
      });
    }
  };

  render() {
    const { inProgress, _id, email, streak } = this.state;
    return (
      <OperationCard>
        <OperationHeading>
          Максимальный рекорд
          <span>Назначить пользователю текущий рекорд, и максимальный, если он меньше</span>
        </OperationHeading>
        <OperationFlex>
          <Group>
            <Input
              type={'text'}
              name={'_id'}
              placeholder={'ID пользователя'}
              value={_id}
              onChange={this.setValue}
            />
            <Text>или</Text>
            <Input
              type={'text'}
              name={'email'}
              placeholder={'E-mail пользователя'}
              value={email}
              onChange={this.setValue}
            />
          </Group>
          <Input
            type={'number'}
            name={'streak'}
            placeholder={'Рекорд'}
            value={streak}
            onChange={this.setValue}
          />
          <OperationButton disabled={inProgress} onClick={this.send}>Назначить</OperationButton>
        </OperationFlex>
      </OperationCard>
    );
  }
}

const Group = styled(Flex)`
  margin-right: 20px;
  flex-grow: 1;
  > p {
    color: ${p => p.theme.colors.gray.light};
  }
`;

const OperationFlex = styled(Flex)`
  input {
    margin: 0 5px;
    &[type="number"] {
      width: 130px;
    }
  }
`;

export default MaxStreak;
