import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

@withRouter
class MenuItem extends PureComponent {
  static propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    mini: PropTypes.bool,
    Icon: PropTypes.elementType,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    mini: false,
  };

  render() {
    const { to, text, Icon, location: { pathname }, mini } = this.props;
    return (
      <MenuLink active={pathname === to} to={to} mini={mini}>
        {Icon && <Icon/>}
        <span>{text}</span>
      </MenuLink>
    );
  }
}

export default MenuItem;

export const menuItemStyle = css`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  width: 100%;
  letter-spacing: 0.02em;
  color: white;
  padding-left: 30px;
  span {
    display: block;
    width: 100%;
    text-align: left;
  }
  svg {
    color: white;
    margin-right: 15px;
    min-width: 15px;
  }
  &:hover, &:hover svg {
    color: white;
  }
  ${p => p.active && css`
    &, & > svg {
      color: white;
    }
    background: rgba(255, 255, 255, 0.1);
  `}
  ${p => p.mini && css`
    font-weight: normal;
    text-transform: capitalize;
    padding: 0 0 10px 30px;
  `}
`;

const MenuLink = styled(({ active, mini, ...rest }) => <Link {...rest} />)`
  ${menuItemStyle};
`;

