import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { Box, Input } from 'core/ui/atoms';
import { EditHeading, EditBlock } from 'days/ui/atoms';

import withUpdateDay from 'days/ui/withUpdateDay';

@withUpdateDay
class DayName extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    updateDay: PropTypes.func,
  };

  state = {
    name: this.props.name || '',
  };

  save = debounce(() => {
    const { name } = this.state;
    this.props.updateDay({ name })
  }, 1000);

  handleChange = (e) => {
    this.setState({ name: e.target.value });
    this.save();
  };

  render() {
    const { name } = this.state;
    return (
      <EditBlock>
        <Box width="100%">
          <EditHeading>Название:</EditHeading>
          <Input type="text" value={name} onChange={this.handleChange}/>
        </Box>
      </EditBlock>
    );
  }
}

export default DayName;
