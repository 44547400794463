import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { graphql } from 'react-apollo';

import { ADD_SHORT_PRACTICE } from '../../api/apollo/mutations';
import { LIST_SHORT_PRACTICES } from '../../api/apollo/queries';
import { Box, SvgIcon, Card } from 'core/ui/atoms';
import { Modal, Input, Button } from 'core/ui/atoms';
import { relativeTimeThreshold } from 'moment';

@graphql(ADD_SHORT_PRACTICE)
class AddShortPracticeModal extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func,
    isVisible: PropTypes.bool,
    toggleShortPracticeModal: PropTypes.func,
  };

  state = {
    isProcessing: false,
    key: '',
  }

  onKeyChange = e => {
    this.setState({
      key: e.target.value,
    });
  };

  addShortPractice = async () => {
    if (this.state.isProcessing) {
      return;
    }
    this.setState({
      isProcessing: true,
    });
    try {
      await this.props.mutate({
        variables: {
          key: this.state.key
        },
        refetchQueries: [{ query: LIST_SHORT_PRACTICES }]
      });
      this.setState({
        isProcessing: false,
      });
      this.props.toggleShortPracticeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        isProcessing: false,
      });
    }
  };

  render() {
    const { isVisible, toggleShortPracticeModal } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        onClose={toggleShortPracticeModal}
      >
        <Input
          value={this.state.key}
          onChange={this.onKeyChange}
        />
        <Button
          onClick={this.addShortPractice}
          disabled={!this.state.key}
        >
          Добавить
        </Button>
      </Modal>
    );
  }
};

export default AddShortPracticeModal;
