import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';

import {
  Page,
  PageTitle,
  Flex,
  Button,
} from 'core/ui/atoms';
import ErrorOccurred from 'core/ui/components/ErrorOccurred';
import Loading from 'core/ui/components/Loading';
import NightItem from 'nights/ui/components/NightItem';
import NightAdd from 'nights/ui/components/NightAdd';

import { LIST_NIGHTS } from 'nights/api/apollo/queries';

class NightsPage extends PureComponent {

  state = {
    active: 'ru',
  };

  changeLocale = () => {
    this.setState(s => ({ active: s.active === 'ru' ? 'en' : 'ru' }));
  };

  render() {
    const { active } = this.state;
    return (
      <Page>
        <PageTitle />
        <Flex>
          <CustomButton onClick={this.changeLocale} disabled={active === 'ru'}>Русский</CustomButton>
          <CustomButton onClick={this.changeLocale} disabled={active === 'en'}>Английский</CustomButton>
        </Flex>
        <Query query={LIST_NIGHTS}>
          {({ data, loading, error }) => {
            if (error) {
              return <ErrorOccurred error={error}/>;
            }
            if (loading) {
              return <Loading/>;
            }
            const nights = data.adminNightList.map(n => ({ ...n.content[active], night: n.night, _id: n._id }));
            return (
              <Flex wrap="wrap">
                {nights.map(night => <NightItem key={night._id} {...night} locale={active} />)}
                <NightAdd />
              </Flex>
            );
          }}
        </Query>
      </Page>
    );
  }
}

export default NightsPage;

const CustomButton = styled(Button)`
  padding: 10px 20px;
  margin: 0 15px 15px 0;
`;
