import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DEFAULT_AVATAR from 'core/assets/images/avatar.png';

class Avatar extends PureComponent {
  static propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    big: PropTypes.bool,
  };

  static defaultProps = {
    src: DEFAULT_AVATAR,
    marginRight: '15px',
  };

  render() {
    const { src, alt, big } = this.props;
    return (
      <Wrap big={big}>
        <Image src={src} alt={alt}/>
      </Wrap>
    );
  }
}

export default Avatar;

const Wrap = styled.div`
  box-shadow: 0 7px 15px rgba(255, 40, 39, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
  margin-right: ${p => p.big ? null : '15px'};
  max-width: ${p => p.big ? 'auto' : '35px'};
  padding: ${p => p.big ? '8px' : 0};
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
`;
