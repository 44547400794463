import gql from 'graphql-tag';

import { listUserData, singleUserData } from './fragments';

export const LIST_USER = gql`
  query adminUserList($sort: AdminUsersSort!, $page: Int, $count: Int, $search: String) {
    adminUserList(sort: $sort, page: $page, count: $count, search: $search) {
      docs {
        ...ListUserData
      }
      total
      limit
    }
  }
  ${listUserData}
`;

export const SINGLE_USER = gql`
  query adminUserSingle($_id: ID!) {
    adminUserSingle(_id: $_id) {
      ...SingleUserData
    }
  }
  ${singleUserData}
`;

export const PENDING_USERS = gql`
  query PendingUsers {
    pendingUsers {
      ...ListUserData
    }
  }
  ${listUserData}
`;

export const CURRENT_DAYS = gql`
  query CurrentDays {
    currentDays {
      day
      count
    }
  }
`;
