import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Box, Card, Flex, SvgIcon } from 'core/ui/atoms';
import withAddNight from '../withAddNight';

@withAddNight
class NightAdd extends PureComponent {
  static propTypes = {
    addNight: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        night: PropTypes.number,
        locale: PropTypes.string,
      }),
    }),
  };

  state = {
    pending: false,
  };

  addNotification = async () => {
    if (!this.state.pending) {
      this.setState({
        pending: true,
      });
      await this.props.addNight();
      this.setState({
        pending: false,
      });
    }
  };

  render() {
    return (
      <Wrap md={4} sm={12} xs={12} onClick={this.addNotification}>
        <Card small>
          <Container disabled={this.state.pending}>
            <SvgIcon.Plus />
          </Container>
        </Card>
      </Wrap>
    );
  }
}

export default NightAdd;

const Wrap = styled(Box)`
  padding: 5px;
  cursor: pointer;
  svg {
    color: ${p => p.theme.colors.primary};
    width: 40px;
    height: 40px;
  }
`;

const Container = styled(Flex)`
  min-height: 90px;
  width: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  transition: opacity 250ms;
  &:hover {
    opacity: ${p => p.disabled ? 0.4 : 1};
  }
`;
