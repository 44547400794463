import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

import {
  Page,
  PageTitle,
  Card,
} from 'core/ui/atoms';
import BasicNumbers from '../components/BasicNumbers';
import ParticipantsByDate from '../components/ParticipantsByDate';
import Checkouts from '../components/Checkouts';
import { LIST_RETREAT_CHECKOUTS } from '../../api/apollo/queries';

@graphql(LIST_RETREAT_CHECKOUTS)
class Retreat extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
  };

  renderMetrics = () => {
    const { data: { listRetreatCheckouts } } = this.props;
    const groupByDate = groupBy(listRetreatCheckouts, c => c.checkoutData.date);
    const todayLimit = moment().subtract(1, 'days').valueOf();
    const total = listRetreatCheckouts.length;
    const paidTotal = listRetreatCheckouts.filter(c => c.status === 'PAID').length;
    const today = listRetreatCheckouts.filter(c => c.createdAt > todayLimit).length;
    const paidToday = listRetreatCheckouts.filter(c => c.createdAt > todayLimit && c.status === 'PAID').length;
    return (
      <>
        <BasicNumbers total={total} paidTotal={paidTotal} today={today} paidToday={paidToday} />
        <ParticipantsByDate groupByDate={groupByDate} />
        <Checkouts checkouts={sortBy(listRetreatCheckouts, 'checkoutData.date')} />
      </>
    )
  };

  render() {
    const { data } = this.props;
    return (
      <Page>
        <PageTitle>Ретрит</PageTitle>
        <Content>
          {data.loading ? (
            <Card>
              <LoadingCont>Loading...</LoadingCont>
            </Card>
          ) : this.renderMetrics()}
        </Content>
      </Page>
    );
  }
}

const LoadingCont = styled.p`
  padding: 23px;
  font-size: 18px;
  font-weight: 500;
  color: ${p => p.theme.colors.gray.regular};
`;

const Content = styled.div`
  margin: 0 15px 15px 0;
`;

export default Retreat;
