import gql from 'graphql-tag';

import { orderFragment } from './fragments';

export const TOGGLE_FAKE = gql`
  mutation toggleFakeOrder($orderId: ID!) {
    toggleFakeOrder(orderId: $orderId) {
      ...AdminOrderData
    }
  }
  ${orderFragment}
`;

export const RECALCULATE_MRR = gql`
  mutation recalculateMrr {
    recalculateMrr
  }
`;