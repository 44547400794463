import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import {
  TableRow,
  TableCell,
  Flex,
  Avatar,
} from 'core/ui/atoms';

@withRouter
class UsersTableRow extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      _id: PropTypes.string,
      personalData: PropTypes.shape({
        avatar: PropTypes.string,
        city: PropTypes.string,
        firstName: PropTypes.string,
      }),
      serviceData: PropTypes.shape({
        currentDay: PropTypes.number,
      }),
      lastActiveAt: PropTypes.string,
      createdAt: PropTypes.string,
    }),
    history: PropTypes.object,
  };

  goToDetail = () => {
    const { history, user } = this.props;
    history.push(`/user/${user._id}`);
  };

  render() {
    const {
      personalData: { avatar, timezone, firstName },
      serviceData: { currentDay },
      lastActiveAt,
      createdAt
    } = this.props.user;
    const lastActivity = moment(lastActiveAt).format('DD.MM.YYYY');
    const created = moment(createdAt).format('DD.MM.YYYY');
    return (
      <TableRow onClick={this.goToDetail}>
        <TableCell>
          <Flex alignItems="center" justifyContent="center">
            <Avatar src={avatar || undefined} alt={firstName}/>{firstName}
          </Flex>
        </TableCell>
        <TableCell>{timezone}</TableCell>
        <TableCell>{currentDay}</TableCell>
        <TableCell>{lastActivity}</TableCell>
        <TableCell>{created}</TableCell>
      </TableRow>
    );
  }
}

export default UsersTableRow;


