import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { UPDATE_DAY } from 'days/api/apollo/mutations';
import { LIST_DAY, SINGLE_DAY } from 'days/api/apollo/queries';

const withUpdateDay = Component => (
  @withRouter
  @graphql(UPDATE_DAY, { name: 'updateDay' })
  class Wrapper extends PureComponent {
    static propTypes = {
      name: PropTypes.string,
      updateDay: PropTypes.func,
      match: PropTypes.shape({
        params: PropTypes.shape({
          day: PropTypes.number,
          locale: PropTypes.string,
        }),
      }),
    };

    updateDay = (data) => {
      const { updateDay, match: { params: { day: d, locale } } } = this.props;
      const day = Number(d);
      data.day = day;
      data.locale = locale;
      return updateDay({
        variables: { data },
        refetchQueries: [
          { query: SINGLE_DAY, variables: { day }, },
          { query: LIST_DAY }
        ]
      });
    };

    render() {
      const { updateDay, ...rest } = this.props;
      return (
        <Component {...rest} updateDay={this.updateDay}/>
      );
    }
  }
);

export default withUpdateDay;
