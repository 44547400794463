import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from 'core/ui/atoms';
import Loading from 'core/ui/components/Loading';
import OrdersTableRow from './OrdersTableRow';

class OrdersTable extends PureComponent {
  static propTypes = {
    docs: PropTypes.array,
    loading: PropTypes.bool,
  };

  render() {
    const { docs, loading } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Дата</TableCell>
            <TableCell>Имя</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Сумма</TableCell>
            <TableCell>Кол-во дней</TableCell>
            <TableCell>MRR</TableCell>
            <TableCell>Статус</TableCell>
            <TableCell>СБП</TableCell>
            <TableCell>Фейк</TableCell>
            <TableCell>Родительский</TableCell>
            <TableCell>Активный</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? <Loading/>
            : docs.map((order) => <OrdersTableRow key={order._id} order={order}/>)
          }
        </TableBody>
      </Table>
    );
  }
}

export default OrdersTable;
