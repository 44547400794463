import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';

import {
  Page,
  PageTitle,
  Flex,
  Button,
} from 'core/ui/atoms';
import ErrorOccurred from 'core/ui/components/ErrorOccurred';
import DayItem from '../components/DayItem';
import DayAdd from 'days/ui/components/DayAdd';
import Loading from 'core/ui/components/Loading';

import { LIST_DAY } from 'days/api/apollo/queries';

class NewUsersPage extends PureComponent {

  state = {
    active: 'ru',
  };

  changeLocale = () => {
    this.setState(s => ({ active: s.active === 'ru' ? 'en' : 'ru' }));
  };

  render() {
    const { active } = this.state;
    return (
      <Page>
        <PageTitle/>
        <Flex>
          <CustomButton onClick={this.changeLocale} disabled={active === 'ru'}>Русский</CustomButton>
          <CustomButton onClick={this.changeLocale} disabled={active === 'en'}>Английский</CustomButton>
        </Flex>
        <Query query={LIST_DAY}>
          {({ data, loading, error }) => {
            if (error) {
              return <ErrorOccurred error={error}/>;
            }
            if (loading) {
              return <Loading/>;
            }
            const days = data.adminDayList.map(d => ({ ...d.content[active], day: d.day, uuid: d.uuid }));
            return (
              <Flex wrap="wrap">
                {days.map(day => <DayItem key={day.uuid} {...day} locale={active} />)}
                <DayAdd />
              </Flex>
            );
          }}
        </Query>
      </Page>
    );
  }
}

export default NewUsersPage;

const CustomButton = styled(Button)`
  padding: 10px 20px;
  margin: 0 15px 15px 0;
`;
