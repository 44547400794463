import gql from 'graphql-tag';

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!, $isAdmin: Boolean) {
    signIn(email: $email, password: $password, isAdmin: $isAdmin) {
      token
    }
  }
`;

export const SIGN_IN_TFA = gql`
  mutation signInTfa($email: String!, $password: String!, $isAdmin: Boolean) {
    signInTfa(email: $email, password: $password, isAdmin: $isAdmin) {
      qr
      url
    }
  }
`;

export const GENERATE_TFA_CODE = gql`
  mutation GenerateTfaCode($email: String!, $password: String!, $isAdmin: Boolean) {
    generateTfaCode(email: $email, password: $password, isAdmin: $isAdmin) {
      qr
      url
    }
  }
`;

export const VALIDATE_TFA_CODE = gql`
  mutation validateTfaCode($email: String!, $password: String!, $code: String!) {
    validateTfaCode(email: $email, password: $password, code: $code) {
      token
    }
  }
`;
