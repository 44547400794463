import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';

import {
  Flex,
  Text,
  Input,
} from 'core/ui/atoms';
import { OperationHeading, OperationCard, OperationButton } from 'operations/ui/atoms';
import { ADMIN_SEND_TO_DAY } from 'operations/api/apollo/mutations';
import { withInAppNotification } from 'core/api/toast/withInAppNotification';

@graphql(ADMIN_SEND_TO_DAY)
@withInAppNotification
class SendToDay extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func,
    showNotification: PropTypes.func,
  };

  state = {
    inProgress: false,
    _id: '',
    email: '',
    number: '',
  };

  setValue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  send = async () => {
    const { _id, email, number } = this.state;
    if (!_id && !email) {
      return this.props.showNotification({
        title: 'Введите мейл или ID',
        message: 'Без этого никак :)',
        type: 'ERROR',
      });
    }
    if (!number) {
      return this.props.showNotification({
        title: 'Укажите номер дня',
        message: 'Как по твоему машина догадается на какой день? :)',
        type: 'ERROR',
      });
    }
    const { data: { adminSendToDay: { success, error } } } = await this.props.mutate({
      variables: {
        email: this.state.email,
        _id: this.state._id,
        day: this.state.number
      }
    });
    if (error) {
      if (error === 'USER_NOT_FOUND') {
        return this.props.showNotification({
          title: 'Пользователь не найден',
          message: 'Проверьте данные. Например в ID нужно передавать именно ObjectId',
          type: 'ERROR',
        });
      }
      return this.props.showNotification({
        title: 'Что-то не так',
        message: 'Напишите разработчикам, опять какой-то косяк',
        type: 'ERROR',
      });
    }
    if (success) {
      this.setState({
        _id: '',
        email: '',
        number: '',
      });
      return this.props.showNotification({
        title: 'Ура!',
        message: 'Пользователь теперь на нужном дне',
        type: 'SUCCESS',
      });
    }
  };

  render() {
    const { inProgress, _id, email, number } = this.state;
    return (
      <OperationCard>
        <OperationHeading>
          Перевести на день
          <span>Перевод пользователя на конкретный день курса, с удалением или добавлением недостающих дней</span>
        </OperationHeading>
        <OperationFlex>
          <Group>
            <Input
              type={'text'}
              name={'_id'}
              placeholder={'ID пользователя'}
              value={_id}
              onChange={this.setValue}
            />
            <Text>или</Text>
            <Input
              type={'text'}
              name={'email'}
              placeholder={'E-mail пользователя'}
              value={email}
              onChange={this.setValue}
            />
          </Group>
          <Input
            type={'number'}
            name={'number'}
            placeholder={'Номер дня'}
            value={number}
            onChange={this.setValue}
          />
          <OperationButton disabled={inProgress} onClick={this.send}>Перевести</OperationButton>
        </OperationFlex>
      </OperationCard>
    );
  }
}

const Group = styled(Flex)`
  margin-right: 20px;
  flex-grow: 1;
  > p {
    color: ${p => p.theme.colors.gray.light};
  }
`;

const OperationFlex = styled(Flex)`
  input {
    margin: 0 5px;
    &[type="number"] {
      width: 130px;
    }
  }
`;

export default SendToDay;
