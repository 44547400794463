import React, { PureComponent } from 'react';

class NotFound extends PureComponent {
  render() {
    return (
      <div>
        <h3>Page not found</h3>
        <p>Check the url</p>
      </div>
    )
  }
}

export default NotFound;
