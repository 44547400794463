// Layouts
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import NightsPage from 'nights/ui/pages/NightsPage';
import NightContentPage from 'nights/ui/pages/NightContentPage';

export default [
  {
    component: NightsPage,
    layout: AuthenticatedLayout,
    path: '/nights'
  },
  {
    component: NightContentPage,
    layout: AuthenticatedLayout,
    path: '/night-content/:locale/:night'
  },
];
