import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import { Card, Flex, Heading } from '../../../core/ui/atoms';
import { MetricItem } from '../atoms';
import {
  GRAPH_RANGES,
  GRAPH_TYPES,
  PRECISE_RANGES,
  PLATFORMS
} from '../../api/constants';
import {
  GET_METRICS_MONETIZE_STATIC,
  GET_METRICS_MONETIZE_GRAPH,
  GET_METRICS_MONETIZE_TOTAL
} from '../../api/apollo/queries';
import MoneyGraph from './MoneyGraph';
import MoneyTotal from './MoneyTotal';

const SOURCES_GROUP_MOCK = [
  { title: 'Все группы', value: 'ALL' },
  { title: 'Органика', value: 'ORGANIC' },
  { title: 'Реклама', value: 'ADS' },
  { title: 'Инстаграм', value: 'INSTAGRAM' },
  { title: 'Микроинфлюенсеры', value: 'MICROINFLUENCERS' },
  { title: 'Инфлюенсеры', value: 'INFLUENCERS' },
];

const SOURCES_MOCK = [
  { title: 'Все источники', value: 'Все' },
  { title: 'Поиск в сторе', value: 'store' },
  { title: 'Реклама 1', value: 'ads_1' },
  { title: 'Реклама 2', value: 'ads_2' },
];

const EXPERIMENTS_MOCK = [
  { title: 'Все эксперименты', value: 'Все' },
  { title: 'Оплата 300', value: 'payment_300' },
  { title: 'Оплата 600', value: 'payment_600' },
  { title: 'Оплата 900', value: 'payment_900' },
];

const MoneyCard = () => {
  const [graphRange, setGraphRange] = useState(GRAPH_RANGES[0].value);
  const [sourcesRange, setSourcesRange] = useState(PRECISE_RANGES[0].value);
  const [platform, setPlatform] = useState(PLATFORMS[0].value);
  const [sourceGroup, setSourceGroup] = useState(SOURCES_GROUP_MOCK[0].value);
  const [source, setSource] = useState(SOURCES_GROUP_MOCK[0].value);
  const [experiment, setExperiment] = useState(EXPERIMENTS_MOCK[0].value);
  const [graphType, setGraphType] = useState(GRAPH_TYPES[0].value);

  const setSelectValue = stateUpdate => e => stateUpdate(e.target.value);

  return (
    <Card padded>
      <Heading>Монетизация</Heading>
      <Flex>
        <StaticCont>
          <Flex>
            <Query query={GET_METRICS_MONETIZE_STATIC}>
              {({ loading, data }) => (
                <>
                  <MetricItem title={'Оплат всего'} isLoading={loading}>
                    {data && data.getMetricsMonetizeStatic && data.getMetricsMonetizeStatic.totalPayments}
                  </MetricItem>
                  <MetricItem title={'Отмен всего'} isLoading={loading}>
                    {data && data.getMetricsMonetizeStatic && data.getMetricsMonetizeStatic.totalSkipped}
                  </MetricItem>
                  <MetricItem title={'Оплат за сегодня'} isLoading={loading}>
                    {data && data.getMetricsMonetizeStatic && data.getMetricsMonetizeStatic.todayPayments}
                  </MetricItem>
                  <MetricItem title={'Отмен за сегодня'} isLoading={loading}>
                    {data && data.getMetricsMonetizeStatic && data.getMetricsMonetizeStatic.todaySkipped}
                  </MetricItem>
                  <MetricItem title={'Попыток за сегодня'} isLoading={loading}>
                    {data && data.getMetricsMonetizeStatic && data.getMetricsMonetizeStatic.todayTrials}
                  </MetricItem>
                </>
              )}
            </Query>
          </Flex>
          <Flex>
            <Select value={graphType} onChange={setSelectValue(setGraphType)}>
              {GRAPH_TYPES.map(r => (
                <option key={`graph_type_money-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
            <Select value={graphRange} onChange={setSelectValue(setGraphRange)}>
              {GRAPH_RANGES.map(r => (
                <option key={`users-range-money-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
          </Flex>
          <ChartCont>
            <Query query={GET_METRICS_MONETIZE_GRAPH} variables={{ range: graphRange, graphType }}>
              {result => <MoneyGraph result={result} range={graphRange} graphType={graphType} />}
            </Query>
          </ChartCont>
        </StaticCont>
        <TotalCont>
          <Flex wrap={'wrap'}>
            <Select value={sourcesRange} onChange={setSelectValue(setSourcesRange)}>
              {PRECISE_RANGES.map(r => (
                <option key={`sources-money-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
            <Select value={platform} onChange={setSelectValue(setPlatform)}>
              {PLATFORMS.map(r => (
                <option key={`platform-money-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
            <Select value={sourceGroup} onChange={setSelectValue(setSourceGroup)}>
              {SOURCES_GROUP_MOCK.map(r => (
                <option key={`sources-group-money-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
            <Select value={source} onChange={setSelectValue(setSource)}>
              {SOURCES_MOCK.map(r => (
                <option key={`sources--money-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
            <Select value={experiment} onChange={setSelectValue(setExperiment)}>
              {EXPERIMENTS_MOCK.map(r => (
                <option key={`exp--money-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
          </Flex>
          <Query
            query={GET_METRICS_MONETIZE_TOTAL}
            variables={{
              sourcesRange,
              device: platform,
              sourceGroup,
              source,
              experiment,
            }}
          >
            {result => <MoneyTotal result={result} />}
          </Query>
        </TotalCont>
      </Flex>
    </Card>
  );
};

const ChartCont = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
`;

const StaticCont = styled.div`
  flex-grow: 1;
`;

const TotalCont = styled.div`
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 13px;
`;

const Select = styled.select`
  margin-right: 9px;
  margin-bottom: 19px;
`;

export default MoneyCard;
