import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input } from 'core/ui/atoms';

const SilenceInput = ({ mutate, data, pad }) => {
  const [padValue, setPadValue] = useState(pad);

  const updateValue = (e) => {
    setPadValue(e.target.value);
  };

  const update = () => {
    mutate({
      variables: {
        data: {
          ...data,
          pad: padValue ? Number(padValue) : 0,
        },
      },
    }).catch(e => {
      console.log(JSON.stringify(e));
    });
  };

  return (
    <Cont>
      <span>Тишина после: </span>
      <Input
        type={'number'}
        value={padValue || 0}
        onChange={updateValue}
        onBlur={update}
      />
      <span>секунд</span>
    </Cont>
  )
};

SilenceInput.propTypes = {
  mutate: PropTypes.func,
  data: PropTypes.object,
  pad: PropTypes.number,
};

const Cont = styled.div`
  display: flex;
  background: white;
  position: relative;
  padding: 12px;
  border-radius: 6px;
  box-shadow: ${p => p.theme.shadows.level2};
  margin-bottom: 13px;
  margin-top: -9px;
  align-items: center;
  span {
    margin: 0 6px;
    &:nth-child(1) {
      font-weight: 600;
    }
  }
  input {
    width: 70px;
    padding: 3px 9px;
  }
`;

export default SilenceInput;
