import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, Query } from 'react-apollo';
import compose from 'lodash/flowRight';

import { Card, Heading, Flex, Spinner } from '../../../core/ui/atoms';
import { MetricItem } from '../atoms';
import { GRAPH_RANGES, GRAPH_TYPES, PRECISE_RANGES } from '../../api/constants';
import {
  GET_METRICS_USERS_STATIC,
  GET_METRICS_USERS_SOURCES,
  GET_METRICS_USERS_GRAPH,
} from '../../api/apollo/queries';
import UsersGraph from './UsersGraph';
import UsersSources from './UsersSources';

@compose(
  graphql(GET_METRICS_USERS_STATIC, { name: 'staticMetrics' } ),
)
class UsersCard extends PureComponent {
  static propTypes = {
    staticMetrics: PropTypes.object,
    graph: PropTypes.object,
  };

  state = {
    range: GRAPH_RANGES[0].value,
    sourcesRange: PRECISE_RANGES[0].value,
    graphType: GRAPH_TYPES[0].value
  };

  setSelectValue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { range, sourcesRange, graphType } = this.state;
    const {
      staticMetrics: { loading: staticLoading, error: staticError, getMetricsUsersStatic }
    } = this.props;
    return (
      <Card padded>
        <Heading>Пользователи</Heading>
        <Flex>
          <MetricItem title={'Всего'} isLoading={staticLoading}>
            {getMetricsUsersStatic && getMetricsUsersStatic.total}
          </MetricItem>
          <MetricItem title={'Всего активных'} isLoading={staticLoading}>
            {getMetricsUsersStatic && getMetricsUsersStatic.totalActive}
          </MetricItem>
          <MetricItem title={'Cегодня'} isLoading={staticLoading}>
            {getMetricsUsersStatic && getMetricsUsersStatic.today}
          </MetricItem>
          <MetricItem title={'Показов оплат'} isLoading={staticLoading}>
            {getMetricsUsersStatic && getMetricsUsersStatic.paymentsShownToday}
          </MetricItem>
          <MetricItem stress title={'Оплат'} isLoading={staticLoading}>
            {getMetricsUsersStatic && getMetricsUsersStatic.paymentsToday}
          </MetricItem>
          <MetricItem title={'Отмен оплат'} isLoading={staticLoading}>
            {getMetricsUsersStatic && getMetricsUsersStatic.paymentsSkippedToday}
          </MetricItem>
          <MetricItem title={'Отзывов'} isLoading={staticLoading}>
            {getMetricsUsersStatic && getMetricsUsersStatic.reviewsToday}
          </MetricItem>
          <MetricItem title={'Отмен отзывов'} isLoading={staticLoading}>
            {getMetricsUsersStatic && getMetricsUsersStatic.reviewsSkippedToday}
          </MetricItem>
        </Flex>
        <Flex>
          <ChartCont>
            <Select value={range} name={'range'} onChange={this.setSelectValue}>
              {GRAPH_RANGES.map(r => (
                <option key={`users-range-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
            <Select value={graphType} name={'graphType'} onChange={this.setSelectValue}>
              {GRAPH_TYPES.map(r => (
                <option key={`graph_type-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
            <Query query={GET_METRICS_USERS_GRAPH} variables={{ range }}>
              {result => <UsersGraph result={result} range={range} graphType={graphType} />}
            </Query>
          </ChartCont>
          <SourcesCont>
            <Select value={sourcesRange} name={'sourcesRange'} onChange={this.setSelectValue}>
              {PRECISE_RANGES.map(r => (
                <option key={`sources-${r.value}`} value={r.value}>{r.title}</option>
              ))}
            </Select>
            <Query query={GET_METRICS_USERS_SOURCES} variables={{ range: sourcesRange }}>
              {result => (
                <UsersSources result={result} sourcesRange={sourcesRange} />
              )}
            </Query>
          </SourcesCont>
        </Flex>
      </Card>
    );
  }
};

const ChartCont = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
`;

const SourcesCont = styled.div`
  position: relative;
  width: 23%;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-y: scroll;
  //The content within the collaspable area
  .Collapsible__contentInner {
    padding: 10px;
    border-top: 0;
  
    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  //The link which when clicked opens the collapsable area
  .Collapsible__trigger {
    position: relative;
    display: block;
    font-weight: 400;
    text-decoration: none;
    border: 1px solid white;
    padding: 6px 0;
    color: #999999;
    cursor: pointer;
  
    &:after {
      content: '∨';
      position: absolute;
      right: 3px;
      top: 6px;
      display: block;
      transition: transform 300ms;
    }
  
    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  
    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }
`;

const Select = styled.select`
  margin-right: 9px;
  margin-bottom: 19px;
`;

export default UsersCard;
