import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import {
  Page,
  PageTitle,
  Button,
  Flex,
} from 'core/ui/atoms';
import { LIST_STAGES } from '../../api/apollo/queries';
import ErrorOccurred from '../../../core/ui/components/ErrorOccurred';
import Loading from '../../../core/ui/components/Loading';
import TimelineItem from '../components/TimelineItem';
import CreateTimeline from '../components/CreateTimeline';

class Timeline extends PureComponent {
  state = {
    active: 'ru',
    modalData: null,
  };

  changeLocale = () => {
    this.setState(s => ({ active: s.active === 'ru' ? 'en' : 'ru' }));
  };

  render() {
    const { active, modalData } = this.state;
    return (
      <Page>
        <PageTitle>Таймлайн</PageTitle>
        <Flex>
          <CustomButton onClick={this.changeLocale} disabled={active === 'ru'}>Русский</CustomButton>
          <CustomButton onClick={this.changeLocale} disabled={active === 'en'}>Английский</CustomButton>
        </Flex>
        <Content>
          <Query query={LIST_STAGES}>
            {({ error, loading, data }) => {
              if (error) {
                console.log('error', JSON.stringify(error));
                return <ErrorOccurred error={error}/>;
              }
              if (loading) {
                return <Loading/>;
              }
              return (
                <Flex wrap="wrap">
                  {(data || {}).listStages.map((s, i) => (
                    <TimelineItem
                      key={s._id}
                      stage={s}
                      index={i}
                      locale={active}
                    />
                  ))}
                </Flex>
              )
            }}
          </Query>
          <CreateTimeline />
        </Content>
      </Page>
    );
  }
}

const CustomButton = styled(Button)`
  padding: 10px 20px;
  margin: 0 15px 15px 0;
`;

const Content = styled.div`
  margin: 0 15px 15px 0;
`;

export default Timeline;
