// Layouts
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import Timeline from 'timeline/ui/pages/Timeline';

export default [
  {
    component: Timeline,
    layout: AuthenticatedLayout,
    path: '/timeline',
    exact: true,
  },
];
