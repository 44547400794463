import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chart from 'chart.js';

import { Spinner } from '../../../core/ui/atoms';
import { createGraphRange, getDataset, getGraphConfig } from '../../api/helpers';
import { theme } from '../../../core/ui/theme';

let myChart = null;

const GRAPH_HEIGHT = 300;
const GRAPH_WIDTH = 599;

const groupByDate = (range, list) =>
  range.map((r, i) => {
    if (!range[i + 1]) {
      return list.filter(item => item.createdAt > r.date);
    }
    return list.filter(item => item.date > r.isoDate && item.createdAt < range[i + 1].date);
  });

class BehaviorGraph extends PureComponent {
  static propTypes = {
    result: PropTypes.object,
    range: PropTypes.string,
    graphType: PropTypes.string,
  };

  componentDidMount() {
    this.initGraph();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.result.loading && !this.props.result.loading ||
      prevProps.range !== this.props.range
    ) {
      this.initGraph();
    }
  }

  initGraph = () => {
    if (this.props.result.loading) {
      return;
    }
    if (myChart) {
      myChart.destroy();
    }
    const range = createGraphRange(this.props.range);
    const labels = range.map(r => r.name);
    const ctx = document.getElementById('behavior-chart');
    ctx.width = GRAPH_WIDTH;
    ctx.height = GRAPH_HEIGHT;
    const { data: { getMetricsBehaviorGraph } } = this.props.result;
    const instagramGrouped = groupByDate(range, getMetricsBehaviorGraph.instagram);
    const telegramGrouped = groupByDate(range, getMetricsBehaviorGraph.telegram);
    const reviewGrouped = groupByDate(range, getMetricsBehaviorGraph.review);
    const dataLists = [
      { title: 'instagram', list: instagramGrouped },
      { title: 'telegram', list: telegramGrouped },
      { title: 'review', list: reviewGrouped },
    ];
    const datasets = dataLists.map(({ title, list }, i) => {
      const data = list.map(l => l.length);
      return getDataset(title, data, theme.graphColors[i] || theme.graphColors[0]);
    });
    const config = getGraphConfig(labels, datasets);
    myChart = new Chart(ctx, config);
  };

  render() {
    const { result: { loading } } = this.props;
    if (loading) {
      return (
        <GraphLoading>
          <Spinner />
        </GraphLoading>
      );
    }
    return (
      <Canvas id={'behavior-chart'} />
    );
  }
}

const GraphLoading = styled.div`
  width: 599px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f6;
  border-radius: 6px;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 400px;
  margin-right: 5px;
`;

export default BehaviorGraph;
