import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Flex, Select } from 'core/ui/atoms';

class HorizontalSelect extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    after: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array,
    onChange: PropTypes.func,
  };

  render() {
    const { label, after, value, options, onChange } = this.props;
    return (
      <Flex alignItems="center">
        <Label>{label}</Label>
        <Select value={value} onChange={onChange} options={options}/>
        {after && <AfterLabel>{after}</AfterLabel>}
      </Flex>
    );
  }
}

const Label = styled.label`
  font-size: ${p => p.theme.font.size.smaller};
  color: ${p => p.theme.colors.gray.light};
  margin-right: 15px;
  margin-left: 30px;
`;

const AfterLabel = styled.label`
  font-size: ${p => p.theme.font.size.smaller};
  color: ${p => p.theme.colors.gray.light};
  margin-left: 10px;
  margin-right: 30px;
`;

export default HorizontalSelect;
