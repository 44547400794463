import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';

import DayAudio from 'days/ui/components/DayAudio';
import { Button } from 'core/ui/atoms';
import { REMOVE_SUPPORT_AUDIO } from '../../api/apollo/mutations';

const SupportAudio = ({ path, locale, url, day, _id }) => {
  const data = {
    day: parseInt(day, 10),
    locale,
    path,
    _id,
  };
  return (
    <Mutation mutation={REMOVE_SUPPORT_AUDIO} variables={{ data }}>
      {remove => (
        <Cont>
          <Title>{path}</Title>
          <DayAudio src={url} noUpdate />
          <Close onClick={remove}>&times;</Close>
        </Cont>
      )}
    </Mutation>
  )
};

SupportAudio.propTypes = {
  url: PropTypes.string,
  day: PropTypes.number,
  path: PropTypes.string,
  _id: PropTypes.string,
  locale: PropTypes.string,
};

const Cont = styled.div`
  position: relative;
  padding: 12px 50px 23px 12px;
  border-radius: 6px;
  box-shadow: ${p => p.theme.shadows.level2};
  margin-bottom: 13px;
`;

const Title = styled.h5`
  font-size: 16px;
  margin: 0;
`;

const Close = styled(p => <Button {...p} unstyled />)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 33px;
  color: ${p => p.theme.colors.primary};
`

export default SupportAudio;
