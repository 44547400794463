import styled from 'styled-components';

import { Button } from 'core/ui/atoms';

const OperationButton = styled(Button)`
  padding: 13px 29px;
  margin-left: 23px;
`;

export default OperationButton;
