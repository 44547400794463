import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
} from 'core/ui/atoms';
import withUpdateNotification from 'days/ui/withUpdateNotification';

@withUpdateNotification
class NotificationModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    updateNotification: PropTypes.func,
    removeNotification: PropTypes.func,
    time: PropTypes.string,
    text: PropTypes.string,
    _id: PropTypes.string,
  };

  state = {
    time: this.props.time,
    text: this.props.text,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSave = () => {
    const { updateNotification, onClose, _id } = this.props;
    const { text, time } = this.state;
    updateNotification({ text, time, _id }).then(() => {
      onClose();
    });
  };

  onRemove = () => {
    const { removeNotification, onClose, _id } = this.props;
    removeNotification({ _id }).then(() => {
      onClose();
    });
  };

  render() {
    const { onClose } = this.props;
    const { text, time } = this.state;
    return (
      <Modal onClose={onClose}>
        <FormGroup>
          <Label htmlFor="time">Время</Label>
          <Input
            name="time"
            type="time"
            value={time}
            id="time"
            onChange={this.onChange}/>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="text">Текст</Label>
          <Input
            name="text"
            type="text"
            value={text}
            id="text"
            onChange={this.onChange}/>
        </FormGroup>
        <Button onClick={this.onSave}>
          Сохранить
        </Button>
        <Remove unstyled onClick={this.onRemove}>
          Удалить
        </Remove>
      </Modal>
    );
  }
}

const Remove = styled(Button)`
  margin-top: 20px;
  color: ${p => p.theme.colors.primary};
`;

export default NotificationModal;
