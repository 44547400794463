import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MetricItem = ({ title, stress, isLoading, children }) => (
  <Cont>
    <Title>{title}</Title>
    <Value stress={stress}>{isLoading ? '-' : children}</Value>
  </Cont>
);

MetricItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string,
  stress: PropTypes.boolean,
  isLoading: PropTypes.boolean,
};

const Cont = styled.div`
  &:not(:last-of-type) {
    margin-right: 26px;
  }
`;

const Title = styled.h5`
  color: ${p => p.theme.colors.gray.regular};
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 6px;
  margin-top: 0;
`;

const Value = styled.h3`
  color: ${p => p.theme.colors[p.stress ? 'danger' : 'secondary']};
  margin-top: 0;
  font-weight: 800;
`;

export default MetricItem;
