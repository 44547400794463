import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Page,
  PageTitle,
  Card,
  Heading,
  Flex,
  Box,
  SvgIcon,
} from 'core/ui/atoms';
import Loading from 'core/ui/components/Loading';

import { GET_PRACTICE } from '../../api/apollo/queries';
import ErrorOccurred from '../../../core/ui/components/ErrorOccurred';
import AudioItem from '../components/AudioItem';
import UploadAudio from '../components/UploadAudio';

@withRouter
@graphql(GET_PRACTICE, {
  options: ({ match: { params: { _id } } }) => ({
    variables: { _id },
  }),
  name: 'practice',
})
class PracticeContent extends PureComponent {
  static propTypes = {
    practice: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
  };

  goBack = () => {
    this.props.history.goBack();
  };

  listAudio = (part) => {
    const {
      practice: { getPractice, loading, error },
      match: { params: { locale, _id } },
    } = this.props;
    const { content } = getPractice;
    const localizedContent = content ? content[locale] : {};
    if (!localizedContent || !localizedContent.audio || !localizedContent.audio[part]) {
      return null;
    }
    return localizedContent.audio[part].map(a => (
      <AudioItem
        key={a.path}
        locale={locale}
        part={part}
        practiceId={_id}
        {...a}
      />
    ));
  };

  render() {
    const { params: { _id, locale } } = this.props.match;
    const { getPractice, loading, error } = this.props.practice;
    if (error) {
      console.log('error', JSON.stringify(error));
      return <ErrorOccurred error={error}/>;
    }
    if (loading) {
      return <Loading/>;
    }
    const { key, type } = getPractice;
    return (
      <Page>
        <Flex alignItems="center">
          <Back onClick={this.goBack}><SvgIcon.ArrowLeft/></Back>
          <PageTitle>Практика / {locale} / <DayTitle>{key}</DayTitle></PageTitle>
        </Flex>
        <Card padded>
          <div>
            <Heading level={6} noMargin>{type}</Heading>
            <Block>
              <BlockTitle>Интро</BlockTitle>
              <div>
                <AudioFlex>
                  <div>
                    {this.listAudio('theory')}
                    <UploadAudio part={'theory'} _id={_id} locale={locale} />
                  </div>
                </AudioFlex>
              </div>
            </Block>
            <Block>
              <BlockTitle>Практика</BlockTitle>
              <AudioFlex>
                <div>
                  {this.listAudio('practice')}
                  <UploadAudio part={'practice'} _id={_id} locale={locale} />
                </div>
              </AudioFlex>
            </Block>
            <Block>
              <BlockTitle>Поддержкка</BlockTitle>
              <AudioFlex>
                <div>
                  {this.listAudio('support')}
                  <UploadAudio part={'support'} _id={_id} locale={locale} />
                </div>
              </AudioFlex>
            </Block>
          </div>
        </Card>
      </Page>
    );
  }
}

export default PracticeContent;

const Back = styled.div`
  margin-right: 10px;
  display: flex;
  cursor: pointer;
  color: ${p => p.theme.colors.primary};
`;

const AudioFlex = styled.div`
  display: flex;
  width: 600px;
  > div {
    width: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    &:nth-child(2) {
      padding-left: 13px;
    }
  }
`;

const Block = styled.div`
  margin: 23px 0;
`;

const BlockTitle = styled.h6`
  margin: 0;
  margin-bottom: 9px;
`

const DayTitle = styled.span`
  color: ${p => p.theme.colors.primary};
`;

const RightSide = styled(Box)`
  width: 100%;
`;

const FlexWrap = styled(p => <Flex wrap="wrap" {...p}/>)`
  margin: 20px -10px;
`;
