import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';

import { Box, Heading, Card, Flex, SvgIcon, Button } from 'core/ui/atoms';
import { REMOVE_MUSIC } from '../../api/apollo/mutations';
import DayAudio from '../../../days/ui/components/DayAudio';
import { LIST_MUSIC } from '../../api/apollo/queries';

class MusicItem extends PureComponent {
  static propTypes = {
    _id: PropTypes.string,
    content: PropTypes.object,
    audio: PropTypes.object,
    locale: PropTypes.string,
    showModal: PropTypes.func,
  };

  get name() {
    const { content, locale } = this.props;
    return content[locale].title;
  }

  showModal = () => {
    const { _id, content } = this.props;
    this.props.showModal({ _id, content });
  };

  render() {
    const { _id, audio: { path, url }, showModal } = this.props;
    return (
      <Mutation
        mutation={REMOVE_MUSIC}
        variables={{ data: { _id, path } }}
        refetchQueries={[{ query: LIST_MUSIC }]}
      >
        {remove => (
          <Wrap md={4} sm={12} xs={12}>
            <Card small>
              <Flex alignContent={'center'}>
                <DayNumber onClick={this.goToPractice}>
                  <SvgIcon.MusicPlay />
                  <BlueBackground/>
                </DayNumber>
                <DayContent direction={'column'} justifyContent={'center'}>
                  <Title onClick={this.showModal} noMargin>{this.name}</Title>
                  <DayAudio src={url} noUpdate />
                </DayContent>
                <Close onClick={remove}>&times;</Close>
              </Flex>
            </Card>
          </Wrap>
        )}
      </Mutation>
    );
  }
}

const Wrap = styled(Box)`
  position:relative;
  margin-bottom: 13px;
  padding: 5px;
`;

const DayNumber = styled.div`
  position: relative;
  background: ${p => `url(${p.image})` || ''};
  background-size: cover;
  min-width: 66px;
  max-width: 66px;
  min-height: 66px;
  max-height: 66px;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items:center;
  justify-content:center;
  span {
    position: relative;
    top: -10px;
    font-size: 17px;
    color: #fff;
    margin: 0;
    z-index: 3;
  }
`;

const Title = styled(p => <Heading level={6} {...p} />)`
  cursor: pointer;
`;

const BlueBackground = styled.div`
  position: absolute;
  top: 0;
  min-width: 66px;
  max-width: 66px;
  min-height: 66px;
  max-height: 66px;
  background-color: ${p => p.theme.colors.primary};
  opacity: .7;
  z-index: 2;
`;

const DayContent = styled(Flex)`
  height: 66px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #B5B5B5;
`;

const Close = styled(p => <Button {...p} unstyled />)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 33px;
  color: ${p => p.theme.colors.primary};
`

export default MusicItem;
