import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const OrdersStatsCard = ({
  title,
  value,
  additional
}) => {
  return (
    <CardWrap>
      <Card>
        <InnerContent>
          <CardTitle>{title}</CardTitle>
        </InnerContent>
        <Divider />
        <InnerContent>
          <CardValue>{value}</CardValue>
          <CardAdditional>{additional}</CardAdditional>
        </InnerContent>
      </Card>
    </CardWrap>
  );
};

OrdersStatsCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  additional: PropTypes.string,
}

const CardWrap = styled.div`
  width: 33.333333%;
  padding: 12px;
`;

const Card = styled.div`
  border: 0;
  background: white;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 5%);
  border-radius: 8px;
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
`;

const InnerContent = styled.div`
  padding: 16px;
`;

const CardTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: rgb(118, 118, 118);
  margin: 0;
`;

const CardValue = styled.p`
  margin: 0;
  font-size: 26px;
  font-weight: normal;
  line-height: 1.235;
`;

const CardAdditional = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
`;

export default OrdersStatsCard;
