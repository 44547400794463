import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BMF from 'browser-md5-file';
import { graphql } from 'react-apollo';

import { ADD_PRACTICE_AUDIO } from '../../api/apollo/mutations';
import { LIST_PRACTICES } from '../../api/apollo/queries';
import { SvgIcon } from 'core/ui/atoms';

const bmf = new BMF();

@graphql(ADD_PRACTICE_AUDIO)
class UploadAudio extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func,
    practiceKey: PropTypes.func,
    part: PropTypes.string,
    _id: PropTypes.string,
    locale: PropTypes.string,
  };

  editAudio = () => this.input.click();

  getRef = node => this.input = node;

  handleChange = (e) => {
    const { mutate, _id, locale, part } = this.props;
    const [audio] = e.currentTarget.files;
    if (!audio) {
      return;
    }
    const reader = new FileReader();
    this.setState({ loading: true });
    reader.onload = event => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContext.decodeAudioData(event.target.result, buffer => {
        bmf.md5(
          audio,
          (err, md5) => {
            mutate({
              variables: {
                data: {
                  _id,
                  locale,
                  part,
                  audio,
                  time: Math.round(buffer.duration),
                  checksum: md5,
                },
              },
              refetchQueries: [
                { query: LIST_PRACTICES },
              ],
            }).then(() => {
              this.setState({ loading: false });
            }).catch((err) => {
              console.log(JSON.stringify(err));
              this.setState({ loading: true });
            });
          },
          progress => {
            console.log('progress number:', progress);
          },
        );
      });
    };
    reader.onerror = event => {
      console.error('An error ocurred reading the file: ', event);
    };
    reader.readAsArrayBuffer(audio);
  };

  render() {
    return (
      <Cont onClick={this.editAudio}>
        <SvgIcon.Plus />
        <Caption>Загрузить аудио</Caption>
        <Input
          ref={this.getRef}
          type="file"
          accept="audio/*"
          onChange={this.handleChange}
        />
      </Cont>
    );
  }
}

const Input = styled.input`
  display: none;
`;

const Cont = styled.div`
  position: relative;
  display: flex;
  padding: 23px 50px 21px 12px;
  border-radius: 6px;
  box-shadow: ${p => p.theme.shadows.level2};
  margin-top: 13px;
  cursor: pointer;
  svg {
    position:relative;
    top: 3px;
    left: 6px;
    transform: scale(1.3);
    color: ${p => p.theme.colors.primary}
  }
`;

const Caption = styled.h5`
   margin: 0;
   padding-left: 23px;
`;

export default UploadAudio;
