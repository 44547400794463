import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';

import {
  Flex,
} from 'core/ui/atoms';
import { OperationHeading, OperationCard, OperationButton } from 'operations/ui/atoms';
import { NOT_UPDATED_USER_DAYS } from 'operations/api/apollo/queries';
import { CLONE_USER_DAYS } from 'operations/api/apollo/mutations';
import { withInAppNotification } from 'core/api/toast/withInAppNotification';

@graphql(NOT_UPDATED_USER_DAYS)
@graphql(CLONE_USER_DAYS)
@withInAppNotification
class CloneUserDays extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func,
    showNotification: PropTypes.func,
    data: PropTypes.object,
  };

  state = {
    isProcessing: false,
    isRefetching: false,
  };

  setValue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  cloneAll = async () => {
    const N = 37;
    const circle = 1;
    while(circle < N) {
      try {
        await this.clone();
        circle++;
      } catch (e) {
        console.log('ERROR', e);
      }
    }
  };

  clone = async () => {
    const { isProcessing } = this.state;
    if (isProcessing) {
      return;
    }
    this.setState({ isProcessing: true });
    try {
      const { data: { systemCloneUserDaysToSessions } } = await this.props.mutate({
        refetchQueries: [
          {
            query: NOT_UPDATED_USER_DAYS
          }
        ]
      });
      console.log('Clone', systemCloneUserDaysToSessions);
      this.setState({ isProcessing: false });
    } catch(e) {
      console.log(e);
      this.setState({ isProcessing: false });
    }
  };
  
  refetchUserDays = async () => {
    try {
      this.setState({ isRefetching: true });
      await this.props.data.refetch();
      this.setState({ isRefetching: false });
    } catch (e) {
      console.log(e);
      this.setState({ isRefetching: false });
    }
  }

  render() {
    const { isProcessing, isRefetching } = this.state;
    const  { data } = this.props;
    const notUpdatedDays = data && data.systemNotUpdatedUserDays || 0;
    const loading = data && data.loading || false;
    return (
      <OperationCard>
        <OperationHeading>
          {'UserDays --> Sessions'}
        </OperationHeading>
        <OperationFlex>
          {loading ? 'Загрузка...' : notUpdatedDays}
          <OperationButton disabled={loading || isRefetching} onClick={this.refetchUserDays}>Обновить</OperationButton>
        </OperationFlex>
        <OperationFlex>
          <OperationButton disabled={isProcessing} onClick={this.cloneAll}>Копирование</OperationButton>
        </OperationFlex>
      </OperationCard>
    );
  }
}

const OperationFlex = styled(Flex)`
  input {
    margin: 0 5px;
    &[type="number"] {
      width: 130px;
    }
  }
`;

export default CloneUserDays;
