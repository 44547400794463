import styled, { css } from 'styled-components';

const TableCell = styled.div`
  display: table-cell;
  padding: 20px 30px;
  vertical-align: middle;
  text-align: center;
  color: ${p => p.theme.colors[p.color] || p.color || p.theme.colors.gray.regular};
  ${p => p.width && css`
    width: ${p.width};
  `}
`;

export default TableCell;
