import React, { PureComponent } from 'react';
import { Link as LinkAtom } from 'react-router-dom';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Button as ButtonAtom, SvgIcon } from 'core/ui/atoms';

class ButtonEdit extends PureComponent {
  static propTypes = {
    to: PropTypes.string,
    small: PropTypes.bool,
    middle: PropTypes.bool,
    big: PropTypes.bool,
    icon: PropTypes.node,
  };

  static defaultProps = {
    icon: <SvgIcon.Edit/>,
  };

  render() {
    const { to, icon, ...rest } = this.props;
    if (to) {
      return (
        <Link to={to} {...rest}>
          {icon}
        </Link>
      );
    }
    return (
      <Button unstyled rounded {...rest}>
        {icon}
      </Button>
    );
  }
}

const WhiteStyle = css`
  color: white;
  svg {
    ${p => p.small && css`
      height: 55px;
      width: 55px;
    `}
    ${p => p.middle && css`
      height: 70px;
      width: 70px;
    `}
    ${p => p.big && css`
      height: 82px;
      width: 82px;
    `}
    color: white;
  } 
`;

const Link = styled(({ middle, ...props }) => <LinkAtom {...props} />)`
  ${WhiteStyle}
`;

const Button = styled(ButtonAtom)`
  ${WhiteStyle}
`;

export default ButtonEdit;
