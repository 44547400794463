import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';

import { UPDATE_NOTIFICATION, REMOVE_NOTIFICATION } from 'days/api/apollo/mutations';
import { LIST_DAY, SINGLE_DAY } from 'days/api/apollo/queries';

const withUpdateNotification = Component => (
  @withRouter
  @compose(
    graphql(UPDATE_NOTIFICATION, { name: 'updateNotification' }),
    graphql(REMOVE_NOTIFICATION, { name: 'removeNotification' })
  )
  class Wrapper extends PureComponent {
    static propTypes = {
      name: PropTypes.string,
      updateNotification: PropTypes.func,
      removeNotification: PropTypes.func,
      match: PropTypes.shape({
        params: PropTypes.shape({
          day: PropTypes.number,
          locale: PropTypes.string,
        }),
      }),
    };

    updateNotification = (data) => {
      const { updateNotification, match: { params: { day: d, locale } } } = this.props;
      const day = Number(d);
      data.day = day;
      data.locale = locale;
      return updateNotification({
        variables: { data },
        refetchQueries: [
          { query: SINGLE_DAY, variables: { day }, },
          { query: LIST_DAY }
        ]
      });
    };

    removeNotification = (data) => {
      const { removeNotification, match: { params: { day: d, locale } } } = this.props;
      const day = Number(d);
      data.day = day;
      data.locale = locale;
      return removeNotification({
        variables: { data },
        refetchQueries: [
          { query: SINGLE_DAY, variables: { day }, },
          { query: LIST_DAY }
        ]
      });
    };

    render() {
      const { updateNotification, removeNotification, ...rest } = this.props;
      return (
        <Component
          {...rest}
          updateNotification={this.updateNotification}
          removeNotification={this.removeNotification}
        />
      );
    }
  }
);

export default withUpdateNotification;
