import React, { PureComponent } from 'react';
import styled from 'styled-components';

import {
  Page,
  Container,
  Input,
  Card,
  Heading as HeadingAtom,
  FormGroup,
  Primary,
  Button,
  InputErrorMessage,
} from 'core/ui/atoms/index';
import {
  loginWithPassword,
  loginWithPasswordTfa,
  validateTfaCode,
} from 'core/api/actions';
import ErrorBoundary from '../components/ErrorBoundary';

class LoginPage extends PureComponent {
  state = {
    email: '',
    password: '',
    code: '',
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  // onSubmit = () => {
  //   const { email, password } = this.state;
  //   if (!email || !password) {
  //     return;
  //   }
  //   loginWithPasswordTfa(email, password)
  //     .then((data) => {
  //       console.log('data', data);
  //     })
  //     .catch((e) => {
  //       const [error] = e.graphQLErrors;
  //       this.setState({ error: error.message });
  //     });
  // };

  onSubmit = () => {
    const { email, password, code } = this.state;
    if (!email || !password || !code) {
      return;
    }
    this.setState({ pending: true });
    validateTfaCode(email, password, code)
      .then((data) => {
        this.setState({
          email: '',
          password: '',
          code: '',
          pending: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ pending: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    const { email, password, error, code } = this.state;
    return (
      <Page>
        <Container>
          <CardCenter padded>
            <Heading>
              Welcome, <br /> <Primary>Log in</Primary> to continue
            </Heading>
            <form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Input
                  type='email'
                  placeholder='E-mail'
                  value={email}
                  name='email'
                  onChange={this.onChange}
                  disabled={this.state.pending}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type='password'
                  placeholder='Password'
                  value={password}
                  name='password'
                  onChange={this.onChange}
                  disabled={this.state.pending}
                />
              </FormGroup>
              <QrContainer>
                <Input
                  type='text'
                  placeholder='000000'
                  value={code}
                  name='code'
                  onChange={this.onChange}
                  disabled={this.state.pending}
                />
              </QrContainer>
              <Button onClick={this.onSubmit} disabled={this.state.pending} fullWidth>
                {'Log in'}
              </Button>
              <FormGroup>
                {error && <InputErrorMessage>{error}</InputErrorMessage>}
              </FormGroup>
            </form>
          </CardCenter>
        </Container>
      </Page>
    );
  }
}

export default LoginPage;

const Heading = styled((p) => <HeadingAtom level={3} {...p} />)`
  margin-top: 0;
  margin-bottom: 10px;
`;

const CardCenter = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 300px;
  width: 300px;
`;

const QrContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`;
