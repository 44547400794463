import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Flex, Button, Arrow } from 'core/ui/atoms';

const Item = ({ changePage, page, current }) => (
  <PaginationItem onClick={() => changePage(page)} selected={page === current}>
    {page}
  </PaginationItem>
);

Item.propTypes = {
  changePage: PropTypes.func,
  page: PropTypes.number,
  current: PropTypes.number,
};

class Pagination extends PureComponent {
  static propTypes = {
    current: PropTypes.number,
    count: PropTypes.number,
    total: PropTypes.number,
    update: PropTypes.func,
  };

  before = (e) => {
    e.preventDefault();
    const { current, update } = this.props;
    if (current > 1) {
      update(current - 1);
    }
  };

  after = (e) => {
    const { current, update, total, count } = this.props;
    const lastPage = Math.floor(total / count);
    e.preventDefault();
    if (current < lastPage) {
      update(current + 1);
    }
  };

  renderFirst = () => {
    const { current, update } = this.props;
    return (
      <PaginationItem onClick={() => update(1)} selected={1 === current}>
        {1}
      </PaginationItem>
    );
  };

  renderLast = () => {
    const { current, update, total, count } = this.props;
    const lastPage = Math.ceil(total / count);
    return (
      <PaginationItem onClick={() => update(lastPage)} selected={lastPage === current}>
        {lastPage}
      </PaginationItem>
    );
  };

  render() {
    const { update, total, count } = this.props;
    let current = this.props.current;
    const lastPage = Math.ceil(total / count);
    const prevPage = current - 1;
    const nextPage = current + 1;
    return (
      <Flex alignItems="center">
        <Arrow onClick={this.before} left/>
        {this.renderFirst()}
        {current > 3 && <PaginationItem>...</PaginationItem>}
        {current > 2 && <Item changePage={update} page={prevPage} current={current}/>}
        {current < lastPage && current > 1 &&
        <Item changePage={update} page={current} current={current}/>}
        {current < lastPage - 1 && <Item changePage={update} page={nextPage} current={current}/>}
        {current < lastPage - 2 && <PaginationItem>...</PaginationItem>}
        {this.renderLast()}
        <Arrow onClick={this.after} right/>
      </Flex>
    );
  }
}

const PaginationItem = styled(p => <Button unstyled {...p} />)`
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${p => p.selected ? p.theme.colors.primary : 'transparent'};
  font-size: 10px;
  font-weight: 800;
  color: ${p => p.selected ? '#ffffff' : p.theme.colors.gray.light};
  margin-right: 5px;
  margin-left: 5px;
  &:hover {
    ${p => p.onClick && css`
      background: ${p => p.theme.colors.primary};
      color: #ffffff;
    `}
  }
`;


export default Pagination;
