import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Logo } from 'core/ui/atoms';
import SIDEBAR_BG from 'core/assets/images/sidebar_bg.jpg';
import Menu from './Menu';

class Sidebar extends PureComponent {
  render() {
    return (
      <SidebarCont>
        <Link to={'/'}>
          <Logo/>
        </Link>
        <Menu/>
      </SidebarCont>
    );
  }
}

const SidebarCont = styled.div`
  width: 239px;
  height: 100vh;
  padding: 20px 0 20px;
  position: fixed;
  box-sizing: border-box;
  background-image: url(${SIDEBAR_BG});
  background-size: cover;
`;

export default Sidebar;
