import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HISTORY from '../../../core/assets/images/history.png';
import LOGO from '../../../core/assets/images/logo_circle.png';
import PROFILE from '../../../core/assets/images/profile_icon.png';

class PhoneControls extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    day: PropTypes.string,
    night: PropTypes.string,
    isNight: PropTypes.bool,
  };

  render() {
    const { title, day, night, isNight, ...rest } = this.props;
    return (
      <Cont {...rest}>
        <Subtitle>{isNight ? 'Ночь' : 'День'} {isNight ? night : day}</Subtitle>
        <Title>{title}</Title>
        <MeditationCircle>
          <Play />
        </MeditationCircle>
        <Controls>
          <NavItem src={HISTORY} side />
          <NavItem src={LOGO} />
          <NavItem src={PROFILE} side />
        </Controls>
      </Cont>
    );
  }
}

const Cont = styled.div`
  position: relative;
  min-width: 100px;
  min-height: 200px;
`;

const Subtitle = styled.h3`
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  color: white;
  opacity: 0.7;
  margin-bottom: 0;
  margin-top: 23px;
`;

const Title = styled.h3`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: white;
  margin-top: 0;
`;

const MeditationCircle = styled.div`
  position: absolute;
  width: 133px;
  height: 133px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid rgba(255, 255, 255, 0.3);
`;

const Play = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-33%, -50%);
  border-left: 43px solid white;
  border-top: 29px solid transparent;
  border-bottom: 29px solid transparent;
`;

const Controls = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 20px;
  width: 100%;
`;

const NavItem = styled.img`
  width: ${p => p.side ? 26 : 33}px;
  height: ${p => p.side ? 26 : 33}px;
  opacity: ${p => p.side ? 0.39 : 1};
`;

export default PhoneControls;
