import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import debounce from 'lodash/debounce';

import { Modal, Heading, Flex, Input } from 'core/ui/atoms';
import { UPDATE_MUSIC_TITLE } from '../../api/apollo/mutations';
import { LIST_MUSIC } from '../../api/apollo/queries';

@graphql(UPDATE_MUSIC_TITLE)
class EditMusicModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    modalData: PropTypes.object,
    mutate: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = Object
      .entries(this.props.modalData.content)
      .reduce((res, entry) => {
        res[entry[0]] = entry[1].title;
        return res;
      },
      {});
  }

  updateText = (e, locale) => {
    const title = e.target.value;
    this.setState({ [locale]: title });
    this.updateDebounced(locale, title);
  };

  updateDebounced = debounce((locale, title) => {
    const { modalData: { _id } } = this.props;
    this.props.mutate({
      variables: {
        data: {
          _id,
          locale,
          title,
        }
      },
      refetchQueries: [{ query: LIST_MUSIC }],
    }).catch(e => {
      console.log(JSON.stringify(e));
    });
  }, 300);

  render() {
    const { modalData: { _id, content }, onClose } = this.props;
    return (
      <Modal onClose={onClose}>
        <Heading noMargin>Редактировать музыку</Heading>
        <MusicContent>
          {Object.entries(content).map(([locale, { title }]) => (
            <MusicTitle key={locale}>
              <div>{locale}</div>
              <div>
                <Input
                  type={'text'}
                  value={this.state[locale]}
                  onChange={e => this.updateText(e, locale)}
                />
              </div>
            </MusicTitle>
          ))}
        </MusicContent>
      </Modal>
    );
  }
}

const MusicContent = styled.div`
  margin-top: 13px;
`;

const MusicTitle = styled(Flex)`
  margin-bottom: 13px;
  > div {
    &:nth-child(1) {
      width: 33px;
    }
    &:nth-child(2) {
      flex-grow: 1;
    }
  }
`;

export default EditMusicModal;
