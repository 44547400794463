import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import BMF from 'browser-md5-file';

import { Box, Heading, Card, Flex, SvgIcon, Button } from 'core/ui/atoms';
import { ADD_DREAM_PRACTICE_AUDIO, REMOVE_DREAM_PRACTICE_AUDIO } from '../../api/apollo/mutations';
import DayAudio from '../../../days/ui/components/DayAudio';
import { LIST_DREAM_PRACTICES } from '../../api/apollo/queries';

const bmf = new BMF();

@graphql(ADD_DREAM_PRACTICE_AUDIO, { name: 'addAudio' })
@graphql(REMOVE_DREAM_PRACTICE_AUDIO, { name: 'removeAudio' })
class DreamPracticeItem extends PureComponent {
  static propTypes = {
    _id: PropTypes.string,
    content: PropTypes.object,
    key: PropTypes.string,
    practice: PropTypes.object,
    locale: PropTypes.string,
    showModal: PropTypes.func,
    addAudio: PropTypes.func,
    removeAudio: PropTypes.func,
    dreamType: PropTypes.string,
  };

  state = {
    loading: false,
  };

  get name() {
    return this.props.practice && this.props.practice.key || 'Нет названия';
  }

  getRef = node => this.input = node;

  showModal = () => {
    const { _id, content } = this.props;
    this.props.showModal({ _id, content });
  };

  upload = () => {
    this.editAudio();
  };

  remove = () => {
    const { practice: { _id, content }, removeAudio, locale, dreamType } = this.props;
    const path = content && content[locale] && content[locale].path;
    this.setState({ loading: true });
    removeAudio({
      variables: { data: {
        _id, path, locale
      } },
      refetchQueries: [
        { query: LIST_DREAM_PRACTICES, variables: { type: dreamType } },
      ],
    }).then(() => {
      this.setState({ loading: false });
    }).catch((err) => {
      console.log(JSON.stringify(err));
      this.setState({ loading: true });
    });
  };

  toggleModal = () => {
    console.log('MODAL');
  };

  editAudio = () => this.input.click();

  handleChange = (e) => {
    const { practice: { _id }, addAudio, locale, dreamType } = this.props;
    const [audio] = e.currentTarget.files;
    const reader = new FileReader();
    if (!audio) {
      return;
    }
    this.setState({ loading: true });
    reader.onload = event => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContext.decodeAudioData(event.target.result, buffer => {
        bmf.md5(
          audio,
          (err, md5) => {
            addAudio({
              variables: { data: {
                _id, locale, audio, checksum: md5, time: Math.round(buffer.duration)
              } },
              refetchQueries: [
                { query: LIST_DREAM_PRACTICES, variables: { type: dreamType } },
              ],
            }).then(() => {
              this.setState({ loading: false });
            }).catch((err) => {
              console.log(JSON.stringify(err));
              this.setState({ loading: true });
            });
          },
          progress => {
            console.log('progress number:', progress);
          },
        );
      });
    };

    reader.onerror = function (event) {
      console.error('An error ocurred reading the file: ', event);
    };

    reader.readAsArrayBuffer(audio);
  };

  render() {
    const { practice = {}, locale } = this.props;
    const { content } = practice;
    const audioUrl = content && content[locale] && content[locale].url || null;

    return (
      <Wrap md={4} sm={12} xs={12}>
        <Card small>
          <Flex alignContent={'center'}>
            <DayNumber>
              <SvgIcon.MusicPlay />
              <BlueBackground onClick={this.upload} />
              <Input
                ref={this.getRef}
                type="file"
                accept="audio/*"
                onChange={this.handleChange}
              />
            </DayNumber>
            <DayContent direction={'column'} justifyContent={'center'}>
              <Title onClick={this.toggleModal} noMargin>{this.name}</Title>
              {audioUrl ? (
                <DayAudio src={audioUrl} noUpdate />
              ) : (
                <NoAudioText>Аудио не загружено</NoAudioText>
              )}
            </DayContent>
            {audioUrl ? (
              <Close onClick={this.remove}>&times;</Close>
            ) : null}
          </Flex>
        </Card>
      </Wrap>
    );
  }
}

const Wrap = styled(Box)`
  position:relative;
  margin-bottom: 13px;
  padding: 5px;
`;

const DayNumber = styled.div`
  position: relative;
  background: ${p => `url(${p.image})` || ''};
  background-size: cover;
  min-width: 66px;
  max-width: 66px;
  min-height: 66px;
  max-height: 66px;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items:center;
  justify-content:center;
  span {
    position: relative;
    top: -10px;
    font-size: 17px;
    color: #fff;
    margin: 0;
    z-index: 3;
  }
`;

const Title = styled(p => <Heading level={6} {...p} />)`
  cursor: pointer;
`;

const BlueBackground = styled.div`
  position: absolute;
  top: 0;
  min-width: 66px;
  max-width: 66px;
  min-height: 66px;
  max-height: 66px;
  background-color: ${p => p.theme.colors.primary};
  opacity: .7;
  z-index: 2;
`;

const DayContent = styled(Flex)`
  height: 66px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #B5B5B5;
`;

const Close = styled(p => <Button {...p} unstyled />)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 33px;
  color: ${p => p.theme.colors.primary};
`;

const Input = styled.input`
  display: none;
`;

const NoAudioText = styled.p`
  margin: 0;
  color: ${p => p.theme.colors.gray.light};
`;

export default DreamPracticeItem;
