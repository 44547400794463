import gql from 'graphql-tag';

import { nightFragment } from './fragments';

export const LIST_NIGHTS = gql`
  query adminNightList {
    adminNightList {
      ...AdminNightData
    }
  }
  ${nightFragment}
`;

export const SINGLE_NIGHT = gql`
  query adminNightSingle($night: Int!) {
    adminNightSingle(night: $night) {
      ...AdminNightData
    }
  }
  ${nightFragment}
`;
