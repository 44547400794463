// Layouts
import PublicLayout from 'core/ui/layouts/PublicLayout';
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import LoginPage from 'core/ui/pages/LoginPage';
import DashboardPage from 'dashboard/ui/pages/DashboardPage';

export default [
  {
    component: LoginPage,
    layout: PublicLayout,
    isPublic: true,
    redirectLogged: true,
    path: '/sign-in',
  },
  {
    component: DashboardPage,
    layout: AuthenticatedLayout,
    path: '/',
    exact: true,
  },
  {
    component: DashboardPage,
    layout: AuthenticatedLayout,
    path: '/',
    exact: true,
  },
];
