import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import DaysGraph from './DaysGraph';
import { Card, Heading, Flex } from '../../../core/ui/atoms';
import { GRAPH_RANGES, PRECISE_RANGES, PLATFORMS } from '../../api/constants';
import { MetricItem } from '../atoms';
import {
  GET_METRICS_BEHAVIOR_DAYS,
  GET_METRICS_BEHAVIOR_GRAPH,
  GET_METRICS_BEHAVIOR_STATIC,
} from '../../api/apollo/queries';
import BehaviorGraph from './BehaviorGraph';

const GRAPH_TYPES = [
  { title: 'Всего', value: 'ALL' },
  { title: 'По пользователям', value: 'USERS' },
];

const BehaviorCard = () => {
  const [graphType, setGraphType] = useState(GRAPH_TYPES[0].value);
  const [range, setRange] = useState(GRAPH_RANGES[0].value);
  const [sourcesRange, setSourcesRange] = useState(PRECISE_RANGES[0].value);
  const [platform, setPlatform] = useState(PLATFORMS[0].value);

  const setSelectValue = stateUpdate => e => stateUpdate(e.target.value);
  return (
    <Card padded>
      <Heading>Поведение</Heading>
      <Select value={graphType} onChange={setSelectValue(setGraphType)}>
        {GRAPH_TYPES.map(r => (
          <option key={`users-range-${r.value}`} value={r.value}>{r.title}</option>
        ))}
      </Select>
      <Query query={GET_METRICS_BEHAVIOR_DAYS} variables={{ type: graphType }}>
        {result => <DaysGraph result={result} />}
      </Query>
      <Graphs>
        <div>
          <Select value={range} onChange={setSelectValue(setRange)}>
            {GRAPH_RANGES.map(r => (
              <option key={`behavior-range-${r.value}`} value={r.value}>{r.title}</option>
            ))}
          </Select>
          <Query query={GET_METRICS_BEHAVIOR_GRAPH} variables={{ range }}>
            {result => <BehaviorGraph result={result} range={range} />}
          </Query>
        </div>
        <div>
          <Select value={sourcesRange} onChange={setSelectValue(setSourcesRange)}>
            {PRECISE_RANGES.map(r => (
              <option key={`behavior-p-range-${r.value}`} value={r.value}>{r.title}</option>
            ))}
          </Select>
          <Select value={platform} onChange={setSelectValue(setPlatform)}>
            {PLATFORMS.map(r => (
              <option key={`platform-money-${r.value}`} value={r.value}>{r.title}</option>
            ))}
          </Select>
          <Query
            query={GET_METRICS_BEHAVIOR_STATIC}
            variables={{ range: sourcesRange, device: platform }}
          >
            {({ loading, data }) => {
              return (
                <>
                  <Flex>
                    <MetricItem title={'Отзывы'} isLoading={loading}>
                      {data && data.getMetricsBehaviorStatic && data.getMetricsBehaviorStatic.reviewTotal}
                    </MetricItem>
                    <MetricItem title={'Конв. отзывы'} isLoading={loading}>
                      {data && data.getMetricsBehaviorStatic && data.getMetricsBehaviorStatic.reviewConversion}%
                    </MetricItem>
                  </Flex>
                  <Flex>
                    <MetricItem title={'Инстаграм'} isLoading={loading}>
                      {data && data.getMetricsBehaviorStatic && data.getMetricsBehaviorStatic.instagramTotal}
                    </MetricItem>
                    <MetricItem title={'Конв. инстаграм'} isLoading={loading}>
                      {data && data.getMetricsBehaviorStatic && data.getMetricsBehaviorStatic.instagramConversion}%
                    </MetricItem>
                  </Flex>
                  <Flex>
                    <MetricItem title={'Телеграм'} isLoading={loading}>
                      {data && data.getMetricsBehaviorStatic && data.getMetricsBehaviorStatic.telegramTotal}
                    </MetricItem>
                    <MetricItem title={'Конв. телеграм'} isLoading={loading}>
                      {data && data.getMetricsBehaviorStatic && data.getMetricsBehaviorStatic.telegramConversion}%
                    </MetricItem>
                  </Flex>
                </>
              )
            }}
          </Query>
        </div>
      </Graphs>
    </Card>
  );
};

const Select = styled.select`
  margin-right: 9px;
  margin-bottom: 19px;
`;

const Graphs = styled(Flex)`
  margin-top: 33px;
  > div:nth-child(2) {
    padding-left: 33px;
  }
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 400px;
  margin-right: 5px;
`;

export default BehaviorCard;
