import gql from 'graphql-tag';

export const orderFragment = gql`
  fragment AdminOrderData on Order {
    _id
    status
    isFake
    isSBP
    userId
    userName
    userEmail
    amount
    isParent
    isActive
    createdAt
    days
    product
    mrr
  }
`;
