import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Heading, Card, Flex } from 'core/ui/atoms';
import { CLOUDFRONT_URL } from 'core/api/constants';
import { timeFormat } from 'days/api/helpers';
import history from 'core/api/history';

const getAudioText = (practice = {}) => {
  let res = '';
  for (const [key, value] of Object.entries(practice)) {
    if (value && value.url) {
      res += key;
      res += ', ';
    }
  }
  return res;
};

class NightItem extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
      path: PropTypes.string,
    }),
    night: PropTypes.number,
    audio: PropTypes.shape({
      url: PropTypes.string,
      time: PropTypes.number,
    }),
    practice: PropTypes.object,
    locale: PropTypes.oneOf(['en', 'ru']),
  };

  get name() {
    const { name } = this.props;
    return name || 'Названия еще нет';
  }

  get time() {
    const { audio: { time } } = this.props;
    return timeFormat(time) || '00:45:30';
  }

  goToNight = () => {
    const { night, locale } = this.props;
    history.push(`/night-content/${locale}/${night}`)
  };

  render() {
    const { night, locale, image, practice } = this.props;
    const nightName = locale === 'ru' ? 'Ночь' : 'Night';
    const imagePath = image.path ? `${CLOUDFRONT_URL}${image.path}` : '';
    const audioText = getAudioText(practice);

    return (
      <Wrap md={4} sm={12} xs={12}>
        <Card small>
          <Flex alignContent={'center'}>
            <NightNumber image={imagePath} onClick={this.goToNight}>
              <NightHeading>{night}</NightHeading>
              <span>{nightName}</span>
              {!imagePath && <BlueBackground />}
            </NightNumber>
            <NightContent direction={'column'} justifyContent={'center'}>
              {this.name}
              {audioText ? (
                <EmptyAudio>
                  Аудиозапись: <span>{audioText}</span>
                </EmptyAudio>
              ) : (
                <EmptyAudio>
                  Аудиозапись: <span>отсутствует</span>
                </EmptyAudio>
              )}
            </NightContent>
            {/*<NightEdit middle to={`/day-content/${locale}/${day}`} />*/}
          </Flex>
        </Card>
      </Wrap>
    );
  }

}

export default NightItem;

const Wrap = styled(Box)`
  padding: 5px;
`;

const NightHeading = styled(p => <Heading level={1} {...p} />)`
  position: relative;
  font-size: 48px;
  margin: 0;
  z-index: 3;
  color: #fff !important;
`;

const NightNumber = styled.div`
  position: relative;
  background: ${p => `url(${p.image})` || ''};
  background-size: cover;
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  span {
    position: relative;
    top: -10px;
    font-size: 17px;
    color: #fff;
    margin: 0;
    z-index: 3;
  }
`;

const BlueBackground = styled.div`
  position: absolute;
  top: 0;
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
  background-color: ${p => p.theme.colors.primary};
  opacity: .7;
  z-index: 2;
`;

const NightContent = styled(Flex)`
  height: 90px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #B5B5B5;
`;

const EmptyAudio = styled(Flex)`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #B5B5B5;
  span {
    margin-left: 15px;
    color: #DADADA;
  }
`;
