import React, { PureComponent } from 'react';

import UsersCard from '../components/UsersCard';
import MoneyCard from '../components/MoneyCard';
import BehaviorCard from '../components/BehaviorCard';
import {
  Page,
  PageTitle,
  Card,
  Heading,
} from 'core/ui/atoms';

class DashboardPage extends PureComponent {
  render() {
    return (
      <Page>
        <PageTitle/>
        <UsersCard />
        <MoneyCard />
        <BehaviorCard />
      </Page>
    );
  }
}

export default DashboardPage;
