import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';

import {
  Flex,
} from 'core/ui/atoms';
import { OperationHeading, OperationCard, OperationButton } from 'operations/ui/atoms';
import {
  UPDATE_SUBSCRIPTION_STATUS,
  APPLY_SHOULD_CANCEL_SUBSCRIPTION,
  CLEAR_SHOULD_CANCEL_SUBSCRIPTION
} from 'operations/api/apollo/mutations';
import { withInAppNotification } from 'core/api/toast/withInAppNotification';

@graphql(UPDATE_SUBSCRIPTION_STATUS, { name: 'updateSubscriptionStatus' })
@graphql(APPLY_SHOULD_CANCEL_SUBSCRIPTION, { name: 'applyShouldCancelSubscriptions' })
@graphql(CLEAR_SHOULD_CANCEL_SUBSCRIPTION, { name: 'clearShouldCancelSubscriptions' })
@withInAppNotification
class RecalculateSubscriptions extends PureComponent {
  static propTypes = {
    updateSubscriptionStatus: PropTypes.func,
    applyShouldCancelSubscriptions: PropTypes.func,
    clearShouldCancelSubscriptions: PropTypes.func,
    showNotification: PropTypes.func,
  };

  state = {
    isProcessing: false,
  };

  setValue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateSubscriptions = async () => {
    const { isProcessing } = this.state;
    if (isProcessing) {
      return;
    }
    try {
      const { data: { adminUpdateSubscriptionStatus } } = await this.props.updateSubscriptionStatus();
      console.log('adminUpdateSubscriptionStatus', adminUpdateSubscriptionStatus);
      this.setState({ isProcessing: false });
    } catch(e) {
      console.log(e);
      this.setState({ isProcessing: false });
    }
  };

  applyShouldCancel = async () => {
    const { isProcessing } = this.state;
    if (isProcessing) {
      return;
    }
    try {
      const { data: { adminApplyShouldCancelSubscriptions } } = await this.props.applyShouldCancelSubscriptions();
      console.log('adminApplyShouldCancelSubscriptions', adminApplyShouldCancelSubscriptions);
      this.setState({ isProcessing: false });
    } catch(e) {
      console.log(e);
      this.setState({ isProcessing: false });
    }
  };

  clearShouldCancel = async () => {
    const { isProcessing } = this.state;
    if (isProcessing) {
      return;
    }
    try {
      const { data: { adminClearShouldCancelSubscriptions } } = await this.props.clearShouldCancelSubscriptions();
      console.log('adminClearShouldCancelSubscriptions', adminClearShouldCancelSubscriptions);
      this.setState({ isProcessing: false });
    } catch(e) {
      console.log(e);
      this.setState({ isProcessing: false });
    }
  };

  render() {
    const { isProcessing } = this.state;
    return (
      <OperationCard>
        <OperationHeading>
          Update subscriptions
        </OperationHeading>
        <OperationFlex>
          <OperationButton disabled={isProcessing} onClick={this.updateSubscriptions}>Check</OperationButton>
          <OperationButton disabled={isProcessing} onClick={this.applyShouldCancel}>Apply</OperationButton>
          <OperationButton disabled={isProcessing} onClick={this.clearShouldCancel}>Clear</OperationButton>
        </OperationFlex>
      </OperationCard>
    );
  }
}

const OperationFlex = styled(Flex)`
  input {
    margin: 0 5px;
    &[type="number"] {
      width: 130px;
    }
  }
`;

export default RecalculateSubscriptions;
