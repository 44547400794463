import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { darken, rgba } from 'polished';
import { useMutation } from '@apollo/react-hooks';

import {
  TableRow,
  TableCell,
  Flex,
  Button,
} from 'core/ui/atoms';
import { GET_ORDERS_STATS } from 'orders/api/apollo/queries';
import { TOGGLE_FAKE } from 'orders/api/apollo/mutations';

const getColor = status => {
  if (status === 'CONFIRMED') {
    return 'green';
  } else if (status === 'FAILURE' || status === 'REJECTED' || status ==='FAILED') {
    return 'red';
  } else if (status === 'REFUNDED') {
    return 'yellow';
  } else if (status === 'ACTIVE') {
    return 'orange';
  }
  return null;
};

const OrdersTableRow = ({ order }) => {
  const {
    _id,
    status,
    isFake,
    isSBP,
    userId,
    userName,
    userEmail,
    amount,
    isParent,
    isActive,
    createdAt,
    days,
    product,
    mrr,
  } = order;
  const created = moment(createdAt).format('DD.MM.YYYY');

  const [isProcessing, setIsProcessing] = useState(false);

  const [toogleFakeOrder] = useMutation(TOGGLE_FAKE);

  const toggleFake = async () => {
    if (isProcessing) {
      return;
    }
    setIsProcessing(true);
    try {
      await toogleFakeOrder({
        variables: {
          orderId: _id
        },
        refetchQueries: [
          {
            query: GET_ORDERS_STATS
          }
        ]
      });
      setIsProcessing(false);
    } catch (e) {
      console.log(e);
      setIsProcessing(false);
    }
  }

  return (
    <CustomTableRow isParent={isParent} isActive={isActive}>
      <TableCell>{created}</TableCell>
      <TableCell>{userName}</TableCell>
      <TableCell>{userEmail}</TableCell>
      <TableCell>{userId}</TableCell>
      <TableCell>{amount} ₽</TableCell>
      <TableCell>{days} дней</TableCell>
      <TableCell>{mrr ? `${mrr} ₽` : '-'}</TableCell>
      <TableCell color={getColor(status)}>{status}</TableCell>
      <TableCell>{isSBP ? 'Да' : 'Нет'}</TableCell>
      <TableCell>
        <FakeButton onClick={toggleFake} isFake={isFake}>
          {isFake ? 'Да' : 'Нет'}
        </FakeButton>
      </TableCell>
      <TableCell>{isParent ? 'Да' : 'Нет'}</TableCell>
      <TableCell>{isActive ? 'Да' : 'Нет'}</TableCell>
    </CustomTableRow>
  );
}

OrdersTableRow.propTypes = {
  order: PropTypes.object
};

const CustomTableRow = styled(TableRow)`
  ${p => p.isParent && css`
    background: ${p.isActive ? rgba(p.theme.colors.green, 0.5) : rgba(p.theme.colors.red, 0.5)}
  `}
`;

const FakeButton = styled(Button)`
  background: ${p => p.isFake ? p.theme.colors.red : p.theme.colors.green};
  &:hover {
    background: ${p => darken(0.1, p.isFake ? p.theme.colors.red : p.theme.colors.green)};
  }
`;

export default withRouter(OrdersTableRow);


