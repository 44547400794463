import resolve from 'resolve';
import client from './apollo/init';
import { SIGN_IN, VALIDATE_TFA_CODE, GENERATE_TFA_CODE } from './apollo/mutations';
import history from './history';

export const loginWithPassword = (email, password) => {
  return client.mutate({ mutation: SIGN_IN, variables: { email, password, isAdmin: true } })
    .then(({ data: { signIn } }) => {
      if (signIn) {
        localStorage.setItem('token', signIn.token);
        return client.resetStore();
      }
    })
    .then(() => {
      history.push('/users');
    });
};

// GENERATE TFA!
export const loginWithPasswordTfa = async (email, password) => {
  return new Promise((res, rej) => {
    client.mutate({ mutation: GENERATE_TFA_CODE, variables: { email, password, isAdmin: true } })
      .then(({ data: { generateTfaCode } }) => {
        if (generateTfaCode) {
          res(generateTfaCode);
        } else {
          rej();
        }
      })
  });
};

export const validateTfaCode = (email, password, code) => {
  return client.mutate({ mutation: VALIDATE_TFA_CODE, variables: { email, password, code } })
    .then(({ data: { validateTfaCode } }) => {
      if (validateTfaCode) {
        localStorage.setItem('token', validateTfaCode.token);
        return client.resetStore();
      }
    })
    .then(() => {
      history.push('/users');
    });
};

export const logout = () => {
  localStorage.removeItem('token');
  history.push('/sign-in');
  return client.resetStore();
};
