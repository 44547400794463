import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';

import {
  Page,
  PageTitle,
  Card,
  Flex,
  Box,
  SvgIcon,
} from 'core/ui/atoms';
import MainImage from 'days/ui/components/MainImage';
import StoryImage from 'days/ui/components/StoryImage';
import DayName from 'days/ui/components/DayName';
import DayText from 'days/ui/components/DayText';
import DayQuote from 'days/ui/components/DayQuote';
import DayAuthor from 'days/ui/components/DayAuthor';
import UploadAudio from 'days/ui/components/UploadAudio';
import NotificationItem from 'days/ui/components/NotificationItem';
import NotificationAdd from 'days/ui/components/NotificationAdd';
import Loading from 'core/ui/components/Loading';
import UploadSupportAudio from '../components/UploadSupportAudio';
import SilenceInput from 'tracker/ui/components/SilenceInput';
import SupportAudio from '../components/SupportAudio';
import { SINGLE_DAY } from 'days/api/apollo/queries';
import { ADMIN_ADD_DAY_AUDIO_PAD } from '../../api/apollo/mutations';

const PARTS = [
  'theory',
  'practice',
  'support',
  'integration'
];

@withRouter
@compose(
  graphql(SINGLE_DAY, {
    options: ({ match: { params: { day } } }) => ({
      variables: { day: Number(day) },
    }),
    name: 'day',
  }),
  graphql(ADMIN_ADD_DAY_AUDIO_PAD),
)
class DayContentPage extends PureComponent {
  static propTypes = {
    day: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    mutate: PropTypes.func,
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { params: { day, locale } } = this.props.match;
    const { day: { adminDaySingle, loading }, mutate } = this.props;
    if (loading) {
      return <Loading/>;
    }
    const {
      image,
      name,
      description,
      instagramImage,
      quote,
      author,
      audio,
      practice,
      notifications = [],
    } = adminDaySingle.content[locale];
    return (
      <Page>
        <Flex alignItems="center">
          <Back onClick={this.goBack}><SvgIcon.ArrowLeft/></Back>
          <PageTitle>Список дней / <DayTitle>День {day}</DayTitle></PageTitle>
        </Flex>
        <Card padded>
          <Flex>
            <div>
              <MainImage image={image} title={name} day={day} />
              <StoryImage image={instagramImage} />
            </div>
            <RightSide>
              <DayName name={name}/>
              <DayText description={description}/>
              <DayQuote quote={quote}/>
              <DayAuthor author={author}/>
              <AudioContTitle>Полная запись</AudioContTitle>
              <UploadAudio audio={audio}/>
              {PARTS.map(part => {
                const currentPractice = practice || {};
                if (part === 'support') {
                  return (
                    <AudioCont key={`course-day-${part}`}>
                      <AudioContTitle>{part}</AudioContTitle>
                      {(currentPractice.support || []).map(audio => (
                        <>
                          <SupportAudio key={audio._id} locale={locale} day={day} {...audio} />
                          <SilenceCont>
                            <SilenceInput
                              mutate={mutate}
                              data={{ part, day: Number(day), locale, _id: audio._id }}
                              pad={audio.pad}
                            />
                          </SilenceCont>
                        </>
                      ))}
                      <UploadSupportAudio day={day} locale={locale} />
                    </AudioCont>
                  );
                }
                return (
                  <>
                    <AudioCont key={`course-day-${part}`}>
                      <AudioContTitle>{part}</AudioContTitle>
                      <UploadAudio audio={currentPractice[part] || {}} part={part} />
                    </AudioCont>
                    {part !== 'integration' ? (
                      <SilenceCont>
                        <SilenceInput
                          mutate={mutate}
                          data={{ part, day: Number(day), locale }}
                          pad={(currentPractice[part] || {}).pad || 0}
                        />
                      </SilenceCont>
                    ) : null}
                  </>
                )
              })}
            </RightSide>
          </Flex>
          <FlexWrap>
            {notifications.map((notification) => (
              <NotificationItem key={notification._id} notification={notification}/>
            ))}
            <NotificationAdd />
          </FlexWrap>
        </Card>
      </Page>
    );
  }
}

export default DayContentPage;

const Back = styled.div`
  margin-right: 10px;
  display: flex;
  cursor: pointer;
  color: ${p => p.theme.colors.primary};
`;

const DayTitle = styled.span`
  color: ${p => p.theme.colors.primary};
`;

const RightSide = styled(Box)`
  width: 100%;
`;

const FlexWrap = styled(p => <Flex wrap="wrap" {...p}/>)`
  margin: 20px -10px;
`;

const AudioCont = styled.div`
  margin-top: 30px;
`;

const AudioContTitle = styled.h6`
  text-transform: capitalize;
`;

const SilenceCont = styled.div`
  width: 300px;
  margin-top: 13px;
`;
