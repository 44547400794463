import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import { ADD_DREAM_PRACTICE } from '../../api/apollo/mutations';
import { LIST_DREAM_PRACTICES } from '../../api/apollo/queries';
import { Modal, Input, Button } from 'core/ui/atoms';

@graphql(ADD_DREAM_PRACTICE)
class AddDreamPracticeModal extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func,
    isVisible: PropTypes.bool,
    toggleDreamPracticeModal: PropTypes.func,
    type: PropTypes.string,
  };

  state = {
    isProcessing: false,
    key: '',
  }

  onKeyChange = e => {
    this.setState({
      key: e.target.value,
    });
  };

  addDreamPractice = async () => {
    const { type } = this.props;
    if (this.state.isProcessing) {
      return;
    }
    this.setState({
      isProcessing: true,
    });
    try {
      await this.props.mutate({
        variables: {
          key: this.state.key,
          type
        },
        refetchQueries: [{ query: LIST_DREAM_PRACTICES, variables: { type } }]
      });
      this.setState({
        isProcessing: false,
        key: '',
      });
      this.props.toggleDreamPracticeModal();
    } catch (e) {
      console.log(e);
      this.setState({
        isProcessing: false,
      });
    }
  };

  render() {
    const { isVisible, toggleDreamPracticeModal, type } = this.props;

    if (!isVisible) {
      return null;
    }

    return (
      <Modal
        onClose={toggleDreamPracticeModal}
      >
        <Input
          value={this.state.key}
          onChange={this.onKeyChange}
        />
        <Button
          onClick={this.addDreamPractice}
          disabled={!this.state.key}
        >
          Добавить
        </Button>
      </Modal>
    );
  }
};

export default AddDreamPracticeModal;
