import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import ErrorOccurred from 'core/ui/components/ErrorOccurred';
import OrdersTable from 'orders/ui/components/OrdersTable';
import OrdersTableControls from 'orders/ui/components/OrdersTableControls';
import OrdersStats from 'orders/ui/components/OrdersStats';
import { GET_ORDERS, GET_ORDERS_STATS } from 'orders/api/apollo/queries';
import withStore from 'core/api/storeContext';

const OrdersPage = ({ tableOrders, setStore }) => {
  const { sort, count, page } = tableOrders;
  console.log(sort, count, page);

  const { data, loading, error } = useQuery(GET_ORDERS, {
    variables: {
      page,
      count
    }
  });
  const orders = data && data.getOrders && data.getOrders.docs || [];
  const totalOrders = data && data.getOrders && data.getOrders.total || 0;
  const { data: sData, loadinig: sLoading, error: sError } = useQuery(GET_ORDERS_STATS);

  const updateStore = (state) => {
    setStore({ tableOrders: { ...tableOrders, ...state } });
  };

  if (error) {
    return <ErrorOccurred error={error} />;
  }

  return (
    <div>
      <OrdersStats
        stats={sData && sData.getOrdersStats || {}}
      />
      <div>
        <OrdersTableControls
          update={updateStore}
          total={totalOrders}
          {...tableOrders}
        />
        <OrdersTable docs={orders} loading={loading} />
      </div>
    </div>
  );
};

OrdersPage.propTypes = {
  setStore: PropTypes.func,
  tableOrders: PropTypes.shape({
    // sort: PropTypes.string,
    count: PropTypes.number,
    page: PropTypes.number,
  }),
};

OrdersPage.defaultProps = {
  tableOrders: {
    // sort: 'LAST_ACTIVE',
    count: 20,
    page: 0,
  },
};

export default withStore(OrdersPage);
