import React from 'react';
import styled from 'styled-components';

import { Heading } from 'core/ui/atoms';

const EditHeading = styled(p => <Heading noMargin level={6} {...p} />)`
  && {
    color: #CACACA;
    margin: 5px 0;
  }
`;

export default EditHeading;
