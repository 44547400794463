import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Flex } from '../../../core/ui/atoms';
import { MetricItem } from '../atoms';

class MoneyTotal extends PureComponent {
  render() {
    return (
      <>
        <Flex wrap={'wrap'}>
          <MetricItem title={'Общий доход'}>33(24) $</MetricItem>
          <MetricItem title={'Среднее'}>2(1.6)$</MetricItem>
          <MetricItem title={'Среднее c платящего'}>10(7)$</MetricItem>
          <MetricItem title={'Конверсия c показов'}>33%</MetricItem>
          <MetricItem title={'Конверсия общая'}>5%</MetricItem>
        </Flex>
        <MoneyLists>
          <div>
            <h6>Лучшие покупки</h6>
            <div>
              <Ol>
                <li>donation1 - <strong>12</strong> (33/24$)</li>
                <li>donation3 - <strong>3</strong> (24/18$)</li>
                <li>donation6 - <strong>5</strong> (18/15$)</li>
                <li>course1 - <strong>7</strong> (6/3$)</li>
                <li>course2 - <strong>6</strong> (5/5$)</li>
                <li>course4 - <strong>4</strong> (2/2$)</li>
              </Ol>
            </div>
          </div>
          <div>
            <h6>Эксперименты</h6>
            <div>
              <Ol>
                <li><li>donation - <strong>3</strong> (24/18$) 33%</li></li>
                <li><li>paid300 - <strong>3</strong> (24/18$) 22%</li></li>
                <li><li>paid600 - <strong>3</strong> (24/18$) 11%</li></li>
                <li><li>paid900 - <strong>3</strong> (24/18$) 28%</li></li>
              </Ol>
            </div>
          </div>
        </MoneyLists>
      </>
    );
  }
}

const MoneyLists = styled(Flex)`
  width: 100%;
  h6 {
    margin-top: 0;
    margin-bottom: 13px;
  }
  > div {
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    &:nth-child(2) {
      padding-left: 13px;
    }
    > div {
      flex-grow: 1;
      overflow-y: scroll;
    }
  }
`;

const Ol = styled.ol`
  margin: 0;
  padding: 0;
  > li {
    margin-bottom: 6px;
  }
  > strong {
    margin-left: 6px;
  }
`;

export default MoneyTotal;
