import moment from 'moment';
import { theme } from '../../core/ui/theme';

export const createGraphRange = range => {
  if (range === 'WEEK') {
    return Array(7).fill().map((_, i) => {
      const date = moment()
        .subtract('days', i)
        .set({ hours: 0, minutes: 0, seconds: 1 });
      return {
        name: date.format('dd'),
        isoDate: date.valueOf(),
      }
    }).reverse();
  } else if (range === 'MONTH') {
    return Array(30).fill().map((_, i) => {
      const date = moment()
        .subtract('days', i)
        .set({ hours: 0, minutes: 0, seconds: 1 });
      return {
        name: date.format('D'),
        isoDate: date.valueOf(),
      }
    }).reverse();
  } else if (range === 'THREE_MONTH') {
    return Array(90).fill().map((_, i) => {
      const date = moment()
        .subtract('days', i  * 3)
        .set({ hours: 0, minutes: 0, seconds: 1 });
      return {
        name: date.format('D'),
        isoDate: date.valueOf(),
      }
    }).reverse();
  }
};

export const getDataset = (label, data, color) => ({
  label,
  backgroundColor: color,
  borderColor: color,
  borderWidth: 2,
  data,
  fill: false,
});

export const getGraphConfig = (labels, datasets) => ({
  type: 'line',
  data: {
    labels,
    datasets,
  },
  options: {
    distribution: 'linear',
    responsive: false,
    legend: {
      display: true,
      position: 'bottom',
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      x: {
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Month'
        }
      },
      y: {
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Value'
        }
      },
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  }
});
