import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import { Page, PageTitle, Card } from 'core/ui/atoms';
import withStore from 'core/api/storeContext';
import ErrorOccurred from 'core/ui/components/ErrorOccurred';

import { CURRENT_DAYS } from 'users/api/apollo/queries';

@withStore
class CurrentDaysPage extends PureComponent {
  render() {
    return (
      <Page>
        <PageTitle />
        <Query query={CURRENT_DAYS}>
          {({ data, loading, error }) => {
            if (error) {
              return <ErrorOccurred error={error} />;
            }
            const { currentDays = [] } = data || {};
            return (
              <Table>
                <th>День</th>
                <th>Кол-во</th>
                {currentDays.map((d) =>
                  d.day ? (
                    <tr key={d.day}>
                      <td>{d.day}</td>
                      <td><b>{d.count}</b></td>
                    </tr>
                  ) : null
                )}
              </Table>
            );
          }}
        </Query>
      </Page>
    );
  }
}

const Table = styled.table`
  font-size: 14px;
  color: #333333;
  border-width: 1px;
  border-color: #666666;
  border-collapse: collapse;
  th {
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #666666;
    background-color: #dedede;
    text-align: center;
  }
  td {
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #666666;
    background-color: #ffffff;
    text-align: center;
  }
`;

export default CurrentDaysPage;
