import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Box, Card, SvgIcon } from 'core/ui/atoms';
import withUpdateNotification from '../withUpdateNotification';

@withUpdateNotification
class NotificationAdd extends PureComponent {
  static propTypes = {
    updateNotification: PropTypes.func,
    match: PropTypes.shape({
      params: PropTypes.shape({
        day: PropTypes.number,
        locale: PropTypes.string,
      }),
    }),
  };

  addNotification = () => {
    this.props.updateNotification({ time: '', text: '' });
  };

  render() {
    return (
      <Wrap onClick={this.addNotification}>
        <Card padded small>
          <SvgIcon.Plus/>
        </Card>
      </Wrap>
    );
  }
}

export default NotificationAdd;

const Wrap = styled(Box)`
  padding: 10px;
  cursor: pointer;
  svg {
    color: ${p => p.theme.colors.primary};
    width: 40px;
    height: 40px;
  }
`;
