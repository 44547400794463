import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { ButtonEdit } from 'days/ui/atoms';
import { Spinner } from 'core/ui/atoms';

class ImageUpload extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    loading: PropTypes.bool,
  };

  input = null;

  editImage = () => this.input.click();

  getRef = node => this.input = node;

  render() {
    const { loading, onChange } = this.props;
    return (
      <>
        <Input
          ref={this.getRef}
          type="file"
          accept="image/*"
          onChange={onChange}
        />
        <RightBottom>
          {loading && (
            <Spinner/>
          ) || (
            <Edit small onClick={this.editImage}/>
          )}
        </RightBottom>
      </>
    );
  }
}

export default ImageUpload;

const Input = styled.input`
  display: none;
`;

const RightBottom = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Edit = styled(ButtonEdit)`
  svg {
    color: transparent;
  }
`;

