import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Heading, Card, Flex } from 'core/ui/atoms';
import { ButtonEdit } from 'days/ui/atoms';
import DayAudio from './DayAudio';
import { CLOUDFRONT_URL } from 'core/api/constants';
import { timeFormat } from 'days/api/helpers';
import history from 'core/api/history';

class DayItem extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
      path: PropTypes.string,
    }),
    day: PropTypes.number,
    audio: PropTypes.shape({
      url: PropTypes.string,
      time: PropTypes.number,
    }),
    locale: PropTypes.oneOf(['en', 'ru']),
  };

  get name() {
    const { name } = this.props;
    return name || 'Названия еще нет';
  }

  get time() {
    const { audio: { time } } = this.props;
    return timeFormat(time) || '00:45:30';
  }

  goToDay = () => {
    const { day, locale } = this.props;
    history.push(`/day-content/${locale}/${day}`)
  };

  render() {
    const { day, locale, image, audio: { path: audioPath } } = this.props;
    const dayName = locale === 'ru' ? 'День' : 'Day';
    const imagePath = image.path && `${CLOUDFRONT_URL}${image.path}` || '';

    return (
      <Wrap md={4} sm={12} xs={12}>
        <Card small>
          <Flex alignContent={'center'}>
            <DayNumber image={imagePath} onClick={this.goToDay}>
              <DayHeading>{day}</DayHeading>
              <span>{dayName}</span>
              {!imagePath && <BlueBackground/>}
            </DayNumber>
            <DayContent direction={'column'} justifyContent={'center'}>
              {this.name}
              {audioPath && (
                <>
                  <DayAudio src={`${CLOUDFRONT_URL}${audioPath}`}/>
                  <Time>{this.time}</Time>
                </>
              ) || (
                <EmptyAudio>
                  Аудиозапись: <span>отсутствует</span>
                </EmptyAudio>
              )}
            </DayContent>
            {/*<DayEdit middle to={`/day-content/${locale}/${day}`}/>*/}
          </Flex>
        </Card>
      </Wrap>
    );
  }

}

export default DayItem;

const Wrap = styled(Box)`
  padding: 5px;
`;

const DayHeading = styled(p => <Heading level={1} {...p} />)`
  position: relative;
  font-size: 48px;
  margin: 0;
  z-index: 3;
  color: #fff !important;
`;

const DayNumber = styled.div`
  position: relative;
  background: ${p => `url(${p.image})` || ''};
  background-size: cover;
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  span {
    position: relative;
    top: -10px;
    font-size: 17px;
    color: #fff;
    margin: 0;
    z-index: 3;
  }
`;

const BlueBackground = styled.div`
  position: absolute;
  top: 0;
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
  background-color: ${p => p.theme.colors.primary};
  opacity: .7;
  z-index: 2;
`;

const DayContent = styled(Flex)`
  height: 90px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #B5B5B5;
`;

const EmptyAudio = styled(Flex)`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #B5B5B5;
  span {
    margin-left: 15px;
    color: #DADADA;
  }
`;

const Time = styled.div`
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: #B5B5B5;
  margin: 10px 0 0 0;
`;

const DayEdit = styled(ButtonEdit)`
  position: relative;
  top: 15px;
  left: 7px;
`;

