import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Card, Heading, Flex, Spinner } from '../../../core/ui/atoms';
import { MetricItem } from '../../../dashboard/ui/atoms';

class BasicNumbers extends PureComponent {
  static propTypes = {
    total: PropTypes.number,
    paidTotal: PropTypes.number,
    today: PropTypes.number,
    paidToday: PropTypes.number,
  };

  render() {
    const { total, paidTotal, today, paidToday } = this.props;
    return (
      <Card padded>
        <Heading>Покупки</Heading>
        <Flex>
          <MetricItem title={'Сегодня оплат'} isLoading={false}>
            {paidToday}
          </MetricItem>
          <MetricItem title={'Сегодня заявок'} isLoading={false}>
            {today}
          </MetricItem>
          <MetricItem title={'Всего оплат'} isLoading={false}>
            {paidTotal}
          </MetricItem>
          <MetricItem title={'Всего заявок'} isLoading={false}>
            {total}
          </MetricItem>
        </Flex>
      </Card>
    );
  }
}

export default BasicNumbers;
