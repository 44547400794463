// Layouts
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import OrdersPage from 'orders/ui/pages/Orders';

export default [
  {
    component: OrdersPage,
    layout: AuthenticatedLayout,
    path: '/orders'
  },
];
