import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Flex, Search } from 'core/ui/atoms';
import { HorizontalSelect } from 'users/ui/atoms';
import Pagination from 'core/ui/components/Pagination';

class UsersTableControls extends PureComponent {
  state = {
    searchText: '',
  };

  static propTypes = {
    sort: PropTypes.string,
    count: PropTypes.number,
    page: PropTypes.number,
    total: PropTypes.number,
    search: PropTypes.string,
    update: PropTypes.func,
    updateDebounce: PropTypes.func,
  };

  sortOptions = [
    { value: 'LAST_ACTIVE', title: 'Активности' },
    { value: 'NAME', title: 'Имени' },
    { value: 'CREATED', title: 'Регистрации' },
  ];

  countOptions = [
    { value: 20, title: 20 },
    { value: 50, title: 50 },
    { value: 75, title: 75 },
    { value: 100, title: 100 },
  ];

  changeSort = (e) => {
    const sort = e.currentTarget.value;
    this.props.update({ sort });
  };

  changeCount = (e) => {
    const count = Number(e.currentTarget.value);
    this.props.update({ count });
  };

  changePage = (page) => {
    page = page - 1;
    this.props.update({ page });
  };

  changeSearch = (e) => {
    this.setState({ searchText: e.target.value });
    this.props.updateDebounce({ search: e.target.value });
  }

  render() {
    const { sort, count, total, page, search } = this.props;
    return (
      <Wrapper>
        <Flex justifyContent="flex-end">
          <HorizontalSelect value={sort} label="сортировать по" onChange={this.changeSort} options={this.sortOptions} />
          <HorizontalSelect value={count} label="показать" after="результатов" onChange={this.changeCount} options={this.countOptions} />
          {total > count && <Pagination total={total} update={this.changePage} count={count} current={page + 1} />}
        </Flex>
        <Search
          searchValue={this.state.search}
          onSearchValueChange={this.changeSearch}
        />
      </Wrapper>
    );
  }
}

export default UsersTableControls;

const Wrapper = styled.div`
  padding: 30px 30px 20px;
`;
