import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Container } from 'core/ui/atoms';
import PageHeader from '../components/PageHeader';
import Sidebar from '../components/Sidebar';

@withRouter
class AuthenticatedLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object,
    match: PropTypes.object
  };

  render() {
    const Wrap =
      this.props.location.pathname === '/orders' || this.props.match.path === '/user/:id' ? SimpleWrap : Container;
    return (
      <Layout>
        <Sidebar/>
        <Content>
          <Wrap>
            <PageHeader/>
            {this.props.children}
          </Wrap>
        </Content>
      </Layout>
    );
  }
}

export default AuthenticatedLayout;

const Layout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const Content = styled.div`
  width: 100%;
  margin-left: 220px;
`;

const SimpleWrap = styled.div``;
