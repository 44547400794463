import gql from 'graphql-tag';

export const LIST_PRACTICES = gql`
  query listPractices {
    listPractices {
      _id
      key
      type
      content
    }
  }
`;

export const LIST_SHORT_PRACTICES = gql`
  query listShortPractices {
    listShortPractices {
      _id
      key
      type
      content
    }
  }
`;

export const LIST_DREAM_PRACTICES = gql`
  query listDreamPractices($type: DreamPracticeType!) {
    listDreamPractices(type: $type) {
      _id
      key
      type
      content
    }
  }
`;

export const GET_PRACTICE = gql`
  query getPractice($_id: ID!) {
    getPractice(_id: $_id) {
      _id
      key
      type
      content
    }
  }
`;

export const LIST_MUSIC = gql`
  query listMusic {
    listMusic {
      _id
      audio {
        url
        path
        name
        checksum
      }
      content
    }
  }
`;
