import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BMF from 'browser-md5-file';
import { graphql } from 'react-apollo';

import { ADD_MUSIC } from '../../api/apollo/mutations';
import { LIST_MUSIC, LIST_PRACTICES } from '../../api/apollo/queries';
import { Box, SvgIcon, Card } from 'core/ui/atoms';

const bmf = new BMF();

@graphql(ADD_MUSIC)
class AddMusic extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func,
    _id: PropTypes.string,
    locale: PropTypes.string,
  };

  editAudio = () => this.input.click();

  getRef = node => this.input = node;

  handleChange = (e) => {
    const { mutate } = this.props;
    const [audio] = e.currentTarget.files;
    const reader = new FileReader();
    if (!audio) {
      return;
    }
    this.setState({ loading: true });
    reader.onload = event => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContext.decodeAudioData(event.target.result, buffer => {
        bmf.md5(
          audio,
          (err, md5) => {
            mutate({
              variables: { data: { audio, checksum: md5, time: Math.round(buffer.duration) } },
              refetchQueries: [
                { query: LIST_MUSIC },
              ],
            }).then(() => {
              this.setState({ loading: false });
            }).catch((err) => {
              console.log(JSON.stringify(err));
              this.setState({ loading: true });
            });
          },
          progress => {
            console.log('progress number:', progress);
          },
        );
      });
    };

    reader.onerror = function (event) {
      console.error('An error ocurred reading the file: ', event);
    };

    reader.readAsArrayBuffer(audio);
  };

  render() {
    return (
      <Wrap md={4} sm={12} xs={12} onClick={this.editAudio}>
        <Card>
          <SvgIcon.Plus />
          <Caption>Добавить музыку</Caption>
          <Input
            ref={this.getRef}
            type="file"
            accept="audio/*"
            onChange={this.handleChange}
          />
        </Card>
      </Wrap>
    );
  }
}

const Wrap = styled(Box)`
  position: relative;
  display: flex;
  padding: 5px;
  cursor: pointer;
  > div {
    width: 100%;
    padding: 20px 13px;
    display:flex;
  }
  svg {
    position:relative;
    top: 3px;
    left: 6px;
    transform: scale(1.3);
    color: ${p => p.theme.colors.primary}
  }
`;

const Input = styled.input`
  display: none;
`;

const Caption = styled.h5`
   margin: 0;
   padding-left: 23px;
`;

export default AddMusic;
