import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'react-apollo';

import {
  Flex,
  Text,
  Input,
  Textarea as TextareaAtom,
} from 'core/ui/atoms';
import { OperationHeading, OperationCard, OperationButton } from 'operations/ui/atoms';
import { ADMIN_FREE_COURSE } from 'operations/api/apollo/mutations';
import { withInAppNotification } from 'core/api/toast/withInAppNotification';

@graphql(ADMIN_FREE_COURSE)
@withInAppNotification
class SendToDay extends PureComponent {
  static propTypes = {
    mutate: PropTypes.func,
    showNotification: PropTypes.func,
  };

  state = {
    inProgress: false,
    emails: '',
    days: 0,
  };

  setValue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  send = async () => {
    const { emails, days } = this.state;
    const emailsArray = emails.split('\n');
    if (!emails) {
      return this.props.showNotification({
        title: 'Введите emails',
        message: 'По одному адресу в каждой строке',
        type: 'ERROR',
      });
    }
    if (!days || days <= 0) {
      return this.props.showNotification({
        title: 'Укажите кол-во дней',
        message: 'Которые нужно добавить пользователям (месяц = 30 дней)',
        type: 'ERROR',
      });
    }
    const { data: { adminFreeCourse: { success, failed } } } = await this.props.mutate({
      variables: {
        emails: emailsArray,
        days: Number(days),
      }
    });
    console.log('SUCCESS', success.join('\n'));
    console.log('FAILED', failed.join('\n'));
    this.setState({
      emails: failed.join('\n'),
      days: 0,
    });
  };

  render() {
    const { inProgress, emails, days } = this.state;
    return (
      <OperationCard>
        <OperationHeading>
          Бесплатный доступ
          <span>Добавляет n бесплатных дней наачиная с текущего дня пользователям из списка</span>
        </OperationHeading>
        <OperationFlex>
          <Group>
            <Textarea
              name={'emails'}
              placeholder={'Список email пользователей (по одному в строке)'}
              value={emails}
              onChange={this.setValue}
            />
          </Group>
          <Input
            type={'number'}
            name={'days'}
            placeholder={'Кол-во дней'}
            value={days}
            onChange={this.setValue}
          />
          <OperationButton disabled={inProgress} onClick={this.send}>Дать доступ</OperationButton>
        </OperationFlex>
      </OperationCard>
    );
  }
}

const Group = styled(Flex)`
  margin-right: 20px;
  flex-grow: 1;
  > p {
    color: ${p => p.theme.colors.gray.light};
  }
`;

const OperationFlex = styled(Flex)`
  input {
    margin: 0 5px;
    &[type="number"] {
      width: 130px;
    }
  }
`;

const Textarea = styled(TextareaAtom)`
  height: 280px;
`;

export default SendToDay;
