import React from 'react';

import {InAppNotificationsConsumer} from './InAppNotificationsContext';

export const withInAppNotification = Component =>
  class Wrapper extends React.Component {
    render() {
      return (
        <InAppNotificationsConsumer>
          {notificationData => (
            <Component {...this.props} {...notificationData} />
          )}
        </InAppNotificationsConsumer>
      );
    }
  };
